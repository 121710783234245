import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import GoBack from "../components/GoBack";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
// import { useRef } from "react";
import { useEffect } from "react";
import PublicacionesService from "../services/Publicaciones";
import { IMAGE_PATH } from "../constants/Constants";
import CategoriasService from "../services/Categorias";
import Loader from "../components/Loader";
import Avatar from "react-avatar-edit";

const validationSchema = yup.object().shape({
  product: yup
    .string()
    .required("Campo obligatorio")
    .min(2, "campo minimo de 2 caracteres")
    .max(25, "campo máximo de 25 caracteres"),
  price: yup
    .number()
    .typeError("Campo obligatorio")
    .required("Campo obligatorio")
    .min(1, "Valor minimo 1 numero")
    .max(999999, "Valor máximo 999999")
    .positive()
    .integer("Campo de numero entero"),
  id_category: yup
    .number()
    .typeError("Campo obligatorio")
    .required("Campo obligatorio")
    .moreThan(0, "Selecciona una opción"),
  description: yup
    .string()
    .required("Campo obligatorio")
    .min(2, "Campo minimo de 2 caracteres")
    .max(240, "campo máximo de 240 caracteres"),
});

function PublicacionesEditar(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [categorias, setCategorias] = useState([]);
  // const fileRef = useRef(null);
  // const [fileData, setFileData] = useState();
  const [publicacion, setPublicacion] = useState({
    image: null,
    id_categoria: null,
  });

  const [show, setShow] = useState(false);
  const handleClose = () => {
    navigate(-1);
    setShow(false);
  };
  const src = null;
  const [avatar, setAvatar] = useState({
    preview: null,
    src: null,
  });

  const [showChangePhoto, setShowChangePhoto] = useState(false);
  const handleCancelPhoto = () => {
    setShowChangePhoto(false);
    setAvatar({ preview: null });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  // function handleChangeImage(ev) {
  //   const reader = new FileReader();

  //   reader.addEventListener("load", function () {
  //     setFileData(reader.result);
  //     // console.log("fileData = ", fileData);
  //   });
  //   reader.readAsDataURL(fileRef.current.files[0]);
  // }

  async function Perfil() {
    console.log("id Perfil", id);
    const dataUsuario = await PublicacionesService.get(id);
    setValue("id_user", dataUsuario.data.id_user);
    setValue("product", dataUsuario.data.product);
    setValue("price", dataUsuario.data.price);
    setValue("id_category", dataUsuario.data.id_category);
    setValue("description", dataUsuario.data.description);
    setPublicacion({
      id_publication: dataUsuario.data.id_publication,
      image: dataUsuario.data.image,
      id_categoria: dataUsuario.data.id_category,
    });
    console.log("dataUsuario ", dataUsuario);
  }
  useEffect(() => {
    (async () => {
      setLoading(true);
      const Categorias = await CategoriasService.all();
      // console.log("CategoriasService.all() =", Categorias.data);
      setCategorias(Categorias.data);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    Perfil();
  }, []);

  const onClose = () => {
    setAvatar({ preview: null });
  };

  const onCrop = (preview) => {
    setAvatar({ preview: preview });
  };

  function onSubmit(data) {
    setLoading(true);
    console.log("La data recibida es: ", data);
    PublicacionesService.edit({
      ...data,
      id_publication: publicacion.id_publication,
    }).then((data) => {
      console.log("success?s ", data);
      if (data.success) {
        setLoading(false);
        setShow(true);
      }
    });
  }

  const handleChangePhoto = () => {
    setShowChangePhoto(true);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Modal
            show={showChangePhoto}
            onHide={handleCancelPhoto}
            backdrop="static"
            keyboard={false}
            centered
            // className="modal-bottom border-bottom"
          >
            <Modal.Header className="justify-content-center">
              <Modal.Title>Foto</Modal.Title>
            </Modal.Header>
            <Modal.Body className="mb-avatar">
              {loading ? <Loader /> : ""}
              <Form action="#" onSubmit={handleSubmit(onSubmit)}>
                {/* <Button variant="light" size="lg" onClick={handleClick}>
            Selecciona la foto a cambiar
          </Button>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
          /> */}
                <div className="text-center py-2">
                  {avatar.preview ? (
                    <img
                      src={avatar.preview}
                      alt="Preview"
                      className="img-fluid"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="img-fluid d-flex justify-content-center overflow-hidden">
                  <Avatar
                    width={190}
                    height={190}
                    onCrop={onCrop}
                    onClose={onClose}
                    src={src}
                    exportAsSquare={true}
                    minCropRadius={100}
                    minCropRadius={100}
                    // exportSize={200}
                  />
                </div>
                {avatar.preview ? (
                  <div className="d-grid gap-2 py-2">
                    <Button variant="primary" type="submit" size="lg">
                      Publicar
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </Form>
            </Modal.Body>

            <Modal.Footer className="d-block modal-border-top">
              {avatar.preview ? (
                ""
              ) : (
                <div className="d-grid gap-2">
                  <Button variant="light" size="lg" onClick={handleCancelPhoto}>
                    <i className="bi bi-x-lg"></i> Cancelar
                  </Button>
                </div>
              )}
            </Modal.Footer>
          </Modal>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            className="sticky"
          >
            <Modal.Header>
              <Modal.Title>Publicación actualizada</Modal.Title>
            </Modal.Header>
            <Modal.Body>Se actualizo correctamente la publicación</Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={handleClose}>
                Continuar
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="row bg-white ">
            <div
              className="bg-details"
              style={{
                backgroundImage: `url(${
                  publicacion.image
                    ? IMAGE_PATH + "/productos/" + publicacion.image
                    : IMAGE_PATH + "/empty.png"
                })`,
              }}
            ></div>
            <div className="container min-vh-view">
              <div className="row justify-content-center">
                <div className="col-10 arrow">
                  <GoBack />
                </div>
                <div className="col-10">
                  <Form action="#" onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div
                      className="text-center py-3 d-flex justify-content-end align-items-end position-relative"
                      style={{ height: "200px" }}
                    >
                      {/* <button
                        className="btn btn-blue btn-sm"
                        type="button"
                        onClick={handleChangePhoto}
                      >
                        <i className="bi bi-camera fa-2x2"></i>
                      </button> */}
                    </div>
                    <Form.Group className="mb-3" controlId="product">
                      <FloatingLabel
                        controlId="product"
                        label="Producto"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="product"
                          placeholder="Nombre del producto buscado"
                          {...register("product")}
                        />
                      </FloatingLabel>

                      {errors.product && (
                        <div className="alert alert-danger" role="alert">
                          {errors.product.message}
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="price">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Precio"
                        className="mb-3"
                      >
                        <Form.Control
                          type="number"
                          placeholder="Precio a pagar"
                          name="price"
                          {...register("price")}
                        />
                      </FloatingLabel>
                      {errors.price && (
                        <div className="alert alert-danger" role="alert">
                          {errors.price.message}
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="id_category">
                      <FloatingLabel
                        controlId="floatingSelect"
                        label="Categoria"
                      >
                        <Form.Select
                          name="id_category"
                          {...register("id_category", {
                            value: publicacion.id_categoria,
                          })}
                        >
                          <option value="0">Seleccione una opción</option>
                          {categorias.map((item) => (
                            <option
                              value={item.id_category}
                              key={item.id_category}
                            >
                              {item.name}
                            </option>
                          ))}
                        </Form.Select>
                      </FloatingLabel>

                      {errors.id_category && (
                        <div className="alert alert-danger" role="alert">
                          {errors.id_category.message}
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="description">
                      <FloatingLabel
                        controlId="floatingTextarea"
                        label="Descripción"
                        className="mb-3"
                      >
                        <Form.Control
                          as="textarea"
                          style={{ height: "150px" }}
                          name="description"
                          placeholder="Ingrese toda la informacion necesaria del producto solicitado"
                          {...register("description")}
                        />
                      </FloatingLabel>

                      {errors.description && (
                        <div className="alert alert-danger" role="alert">
                          {errors.description.message}
                        </div>
                      )}
                    </Form.Group>

                    {/* <Form.Group controlId="image" className="mb-3">
          <Form.Label>Foto del Producto</Form.Label>
          <Form.Control
            type="file"
            name="image"
            ref={fileRef}
            onChange={handleChangeImage}
          />
        </Form.Group> */}

                    <div className="d-grid gap-2">
                      <Button variant="success" type="submit" size="lg">
                        Actualizar
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PublicacionesEditar;
