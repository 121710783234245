import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GoBack from "../components/GoBack";
import Loader from "../components/Loader";
import { IMAGE_PATH } from "../constants/Constants";
import VendedoresService from "../services/Vendedores";

function Solicitudes() {
  const [solicitudes, setSolicitudes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [empty, setEmpty] = useState(false);

  async function cargarSolicitudes() {
    const data = await VendedoresService.all();
    console.log("cargarPublicaciones: ", data);
    if (data.length == 0) {
      setEmpty(true);
    }
    setSolicitudes(data);
    setLoading(false);
  }

  useEffect(() => {
    cargarSolicitudes();
  }, []);
  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="row bg-white ">
        <div className="container min-vh-view">
          <div className="row justify-content-center">
            <div className="col-10 arrow">
              <GoBack />
            </div>

            <div className="col-10 mb-5">
              {empty ? (
                <div className="py-3">
                  <div className="text-center py-2">
                    <i className="bi bi-send fa-4x"></i>
                  </div>
                  <h3 className="card-title h6 fw-normal">
                    No hay actualmente ninguna solicitud
                  </h3>
                </div>
              ) : (
                ""
              )}
              <ul className="list-group align-items-center">
                {solicitudes.map((item) => (
                  <li className="pb-3" key={item.id_vendor}>
                    <Link
                      to={`../solicitudes/${item.id_vendor}`}
                      className={`row align-items-center ${
                        item.state ? "" : "review"
                      }`}
                    >
                      <div className="col-3 m-auto">
                        <img
                          src={
                            item.users.avatar
                              ? IMAGE_PATH + "/usuarios/" + item.users.avatar
                              : IMAGE_PATH + "/empty.png"
                          }
                          alt={item.users.avatar}
                          className="img-fluid rounded-start"
                        />
                      </div>
                      <div className="col-7">
                        <h3 className="h5">
                          {item.users.first_name + " " + item.users.last_name}
                        </h3>
                        <p className="small">{item.users.username}</p>
                      </div>
                      <div className="col-2">
                        <i className="bi bi-arrow-right-short fa-2x"></i>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Solicitudes;
