import React, { useEffect, useState } from "react";
import CategoriasService from "../services/Categorias.js";
import CategoriasLista from "../components/CategoriasLista.js";
import Loader from "../components/Loader.js";

function Categorias(props) {
  const [categorias, setCategorias] = useState([]);
  const [loading, setLoading] = useState([true]);

  useEffect(() => {
    (async () => {
      const data = await CategoriasService.all();
      console.log("data.data", data.data);
      setCategorias(data.data);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="row bg-white ">
            <div className="container min-vh-view">
              <div className="row justify-content-center">
                <div className="col-10 pb-5">
                  <CategoriasLista items={categorias} auth={props.auth} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Categorias;
