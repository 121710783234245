import { Link } from "react-router-dom";
import { IMAGE_PATH } from "../constants/Constants.js";

function CategoriasListaItem(props) {
  return (
    <>
      <li
        className="col-4 my-3 text-center d-flex align-items-start justify-content-center"
        key={props.item.id_category}
      >
        <Link to={`/categorias/${props.item.id_category}`} className="link">
          <div className="col bg-white">
            {/* {props.item.image ? (
            <img
              src={`${IMAGE_PATH}/${props.item.image}`}
              className="img-fluid rounded-start w-50"
              alt={`imagen de ${props.item.image}`}
            />
          ) : (
            <img
              src={`${IMAGE_PATH}/empty.png`}
              className="img-fluid rounded-start w-50"
              alt="producto sin imagen"
            />
          )}
          <div className="card-body">
            <h5 className="card-title">{props.item.name}</h5>
          </div> */}
            {/* <div className="card-body d-flex justify-content-between">
              <h5 className="card-title">{props.item.name}</h5>
              <i className="bi bi-chevron-right"></i>
            </div> */}
            <div>
              <div className="material-symbols-outlined fa-4x">
                <img
                  src={`./imgs/categories/${props.item.icon}.png`}
                  className="img-fluid pb-2"
                />
              </div>
              <h3 className="h6 text-description">{props.item.name}</h3>
            </div>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CategoriasListaItem;
