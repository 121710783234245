import { Link, useLocation } from "react-router-dom";
import Categorias from "../views/Categorias";
import Home from "../views/Home";

const style = {
  footer: {
    padding: "1em",
    position: "fixed",
    right: "0",
    bottom: "0",
    left: "0",
    zIndex: "1030",
    background: "#EB2C1D!important",
  },
  ul: {
    li: {
      textAlign: "center",
      //   padding: "0.3em",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "50px",
      height: "50px",
      // background: "#FF574A",
      borderRadius: "10px",
    },
    active: {
      border: "1px solid blue!important",
    },
  },
  navLink: {
    padding: 0,
  },
  a: {
    padding: ".5rem 1rem",
    fontSize: "2em!important",
  },
};

function Footer(props) {
  // console.log("props footer = ", props);
  const location = useLocation();
  const { pathname } = location;
  const getLocation = pathname.split("/");
  // console.log(getLocation[1]);
  return (
    <>
      <footer style={style.footer}>
        <ul className="nav nav-pills justify-content-around row mx-3 btn btn-danger">
          <li
            style={style.ul.li}
            className={`nav-link col-2 ${
              getLocation[1] === "home" ? "active" : ""
            }`}
          >
            <Link className="h7 tab-nav text-white" to="/home">
              <div>
                <i className="bi bi-house-door fa-2x"></i>
              </div>
            </Link>
          </li>
          <li
            style={style.ul.li}
            className={`nav-link col-2 ${
              getLocation[1] === "categorias" ? "active" : ""
            }`}
          >
            <Link
              className="h7 tab-nav text-white d-flex flex-column align-items-center"
              to="/categorias"
            >
              <div>
                <i className="bi bi-grid fa-2x"></i>
              </div>
            </Link>
          </li>
          <li
            style={style.ul.li}
            className={`nav-link col-2 d-flex justify-content-center align-items-center ${
              getLocation[1] === "publicaciones" ? "active" : ""
            }`}
          >
            <Link className="h7 tab-nav text-white" to="/publicaciones/nueva">
              <div>
                <i className="bi bi-plus-lg fa-3x"></i>
              </div>
            </Link>
          </li>
          {/* <li style={style.ul.li}
            className={`nav-link col-2 ${
              getLocation[1] === "publicaciones" ? "active" : ""
            }`}
          >
            <Link className="h7 tab-nav text-white" to="/publicaciones">
              <div>
                <i className="bi bi-bell fa-2x"></i>
              </div>
              publicaciones
            </Link>
          </li> */}
          <li
            style={style.ul.li}
            className={`nav-link col-2 ${
              getLocation[1] === "contraofertas" ? "active" : ""
            }`}
          >
            <Link className="h7 tab-nav text-white" to="/contraofertas">
              <div>
                <i className="bi bi-arrow-left-right fa-2x2"></i>
              </div>
            </Link>
          </li>

          <li
            style={style.ul.li}
            className={`nav-link col-2 ${
              getLocation[1] === "perfil" ? "active" : ""
            }`}
          >
            <Link className="h7 tab-nav text-white" to="/perfil">
              <div>
                <i className="bi bi-person fa-2x"></i>
              </div>
            </Link>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default Footer;
