import { useEffect, useState } from "react";
import Busqueda from "../components/Busqueda";
import CategoriasLista from "../components/CategoriasLista";
import PublicacionesLista from "../components/PublicacionesLista";
import AuthService from "../services/Auth";
import PublicacionesService from "../services/Publicaciones";
import SearchField from "react-search-field";
import GoBack from "../components/GoBack";
import { Link, useNavigate } from "react-router-dom";
import ContraofertasService from "../services/Contraofertas";
import { Button, Modal } from "react-bootstrap";
import Loader from "../components/Loader";
import { IMAGE_PATH } from "../constants/Constants";
import moment from "moment";
import "moment/locale/es";
import UseAuthContext from "../hooks/UseAuthContext";

function Contraofertas() {
  const { AuthConsumer } = UseAuthContext();
  console.log("AuthConsumer", AuthConsumer);
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [contraofertas, setContraofertas] = useState([]);
  const [counter, setCounter] = useState(0);
  const [empty, setEmpty] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setCounter(counter + 1);
    setShow(false);
  };

  async function contraofertasAll() {
    const response = await ContraofertasService.getUser(
      AuthConsumer.user.id_user
      // 5
    );
    console.log("contraofertasAll", response);
    if (response.success) {
      setContraofertas(response.data);
    } else {
      setContraofertas("");
    }
    setLoading(false);
  }

  useEffect(() => {
    contraofertasAll();
    // console.log("ContraofertasService.get(id) =", contraofertas);
  }, [counter]);

  async function deleteCounteroffer(id) {
    // console.log("id eliminar = ", id);
    const success = await ContraofertasService.delete(id);
    // console.log("Éxito al eliminar: ", success);
    if (success) {
      setCounter(counter + 1);
    }
  }

  async function agreeCounteroffer(id) {
    const success = await ContraofertasService.edit(id);
    // console.log("agreeCounteroffer: ", success);
    if (success) {
      // setShow(true);
      navigate("/acuerdo");
    }
  }

  return (
    <>
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header>
            <Modal.Title>Contraoferta</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ¡Felicitaciones! realizaron un acuerdo entre ambas partes, se les
            enviara un correo con la imformación de la otra persona para
            coordinar la entrega
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleClose}>
              Continuar
            </Button>
          </Modal.Footer>
        </Modal>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="row bg-white ">
              <div className="container min-vh-view">
                <div className="row justify-content-center">
                  <div className="col-10">
                    {contraofertas.length >= 1 ? (
                      <>
                        {contraofertas.map((item) => (
                          <div className="" key={item.id_counteroffer}>
                            <div className="card border-0">
                              <div className="row g-0">
                                <div className="col-2 m-auto d-flex flex-column">
                                  <img
                                    src={
                                      item.publications.image
                                        ? IMAGE_PATH +
                                          "/productos/" +
                                          item.publications.image
                                        : IMAGE_PATH + "/empty.png"
                                    }
                                    className="img-fluid rounded-start"
                                    alt="sin_imagen"
                                  />
                                  <img
                                    src={
                                      item.users.avatar
                                        ? IMAGE_PATH +
                                          "/usuarios/" +
                                          item.users.avatar
                                        : IMAGE_PATH + "/empty.png"
                                    }
                                    className=" pt-5"
                                    alt="sin_imagen"
                                  />
                                </div>

                                <div className="col-10 p-3">
                                  <div className="btn-light text-decoration-none">
                                    <div className="card-body p-1">
                                      <div className="d-flex justify-content-between">
                                        <div className="">
                                          <h3 className="card-title h6 d-inline">
                                            {item.publications.product}
                                          </h3>

                                          <p className="d-flex justify-content-end">
                                            <small className="text-muted">
                                              {/* publicado el {props.item.created_at} */}

                                              {moment(item.created_at).format(
                                                "LL"
                                              )}
                                            </small>
                                          </p>
                                        </div>
                                      </div>

                                      <div>
                                        <div className="card-text h6 fw-normal">
                                          <p>
                                            {item.users.username} contraoferta
                                          </p>
                                          <p>
                                            ${item.price} de{" "}
                                            <span className="text-decoration-line-through">
                                              ${item.publications.price}
                                            </span>{" "}
                                          </p>
                                        </div>
                                        <button
                                          className="btn btn-cancel me-2"
                                          onClick={() =>
                                            deleteCounteroffer(
                                              item.id_counteroffer
                                            )
                                          }
                                        >
                                          Rechazar
                                        </button>
                                        <button
                                          className="btn btn-success"
                                          onClick={() =>
                                            agreeCounteroffer(
                                              item.id_counteroffer
                                            )
                                          }
                                        >
                                          Aceptar
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <div className="py-4 px-2">
                          <div className="text-center py-2">
                            <i className="bi bi-arrow-left-right fa-4x"></i>
                          </div>

                          <h3 className="card-title h6 fw-normal text-center">
                            No hay actualmente ninguna contaoferta
                          </h3>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </>
  );
}

export default Contraofertas;
