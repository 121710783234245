import { useEffect, useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import GoBack from "../components/GoBack";
import PublicacionesLista from "../components/PublicacionesLista";
import PublicacionesService from "../services/Publicaciones";

function Busqueda() {
  const [publicaciones, setPublicaciones] = useState([]);
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);

  async function cargarPublicaciones() {
    const response = await PublicacionesService.all();
    // console.log("cargarPublicaciones: ", response);
    setPublicaciones(response);
    setLoading(false);
  }
  const publicacionesAll = publicaciones.filter((publication) => {
    return (
      publication.product.toLowerCase().includes(data?.toLowerCase()) ||
      publication.description.toLowerCase().includes(data?.toLowerCase())
    );
  });

  const handleChange = (e) => {
    setData(e.target.value);
  };

  useEffect(() => {
    cargarPublicaciones();
  }, []);
  return (
    <>
      <div className="row bg-white ">
        <div className="container min-vh-view">
          <div className="row justify-content-center">
            {/* <div className="col-10">
              <GoBack />
            </div> */}
            <div className="col-10">
              <FloatingLabel
                controlId="search"
                label="Buscar por..."
                className="mt-4 mb-5"
              >
                <Form.Control
                  type="text"
                  placeholder="Buscar por..."
                  value={data}
                  onChange={handleChange}
                />
              </FloatingLabel>

              <PublicacionesLista items={publicacionesAll} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Busqueda;
