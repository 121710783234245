import { Link } from "react-router-dom";

function Acuerdo(props) {
  return (
    <>
      <div className="row bg-white ">
        <div className="container min-vh-100 d-flex align-items-center">
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="text-center lh-1 pt-5">
                <i className="bi bi-check2-square fa-xl text-success"></i>
                <h2>¡Felicidades!</h2>
              </div>

              <p className="text-description pb-5">
                Se ha generado correctamente un acuerdo entre ambas partes, se
                les enviara por mail la información de la otra persona para que
                puedan coordinar la entrega/venta del producto.
              </p>
              <div className="d-grid gap-2 pt-5">
                <Link to={"/home"} className="btn btn-danger text-white">
                  Volver al Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Acuerdo;
