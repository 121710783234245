import { createContext, useContext } from "react";

const AuthContext = createContext({
  user: {
    id_user: null,
    email: null,
  },
  logged: false,
});

AuthContext.displayName = "AuthContext";

/**
 *
 * @return {{AuthContext: React.Context<{logged: boolean, user: {id_usuario: null, email: null}}>, AuthConsumer: {logged: boolean, user: {id_usuario: null, email: null}}}}
 */
function UseAuthContext() {
  const AuthConsumer = useContext(AuthContext);

  return {
    AuthContext,
    AuthConsumer,
  };
}

export default UseAuthContext;
