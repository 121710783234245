import React, { useEffect, useState } from "react";
import GoBack from "../components/GoBack";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import UsuariosService, { UsuariosServiceFirebase } from "../services/Usuarios";

function Cobertura(props) {
  const [usuarios, setUsuarios] = useState([]);
  const apiKeyMio = "AIzaSyBYhhgSDB2juZHFO9udgoIL8lN_SIT90Dw";
  const mapId = "8e3843c51878e016";
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKeyMio,
  });

  async function cargarUsuarios() {
    const data = await UsuariosService.all();
    // console.log("cargarUsuarios: ", data);
    setUsuarios(data);
  }

  useEffect(() => {
    cargarUsuarios();
  }, []);

  const [center, setCenter] = useState({
    lat: -34.59193836596095,
    lng: -58.45822346706628,
  });
  const [map, setMap] = useState(null);
  const [nearby, setNearby] = useState([]);

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    usuarios.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  if (!isLoaded) return <p>Loading...</p>;
  return (
    <>
      <div className="row">
        <div className="col py-3 arrow">
          <GoBack />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>Cobertura</h2>
          <p>Mapa con la lista de los usuarios y vendedores registrados</p>
          <GoogleMap
            center={center}
            zoom={11}
            mapContainerClassName={"map"}
            options={{ mapId: mapId }}
            onLoad={(map) => setMap(map)}
          >
            {usuarios.map((item) => (
              <Marker
                key={item.id_user}
                position={{
                  lat: parseFloat(item.lat),
                  lng: parseFloat(item.long),
                }}
                onClick={() => handleActiveMarker(item.id_user)}
              >
                {activeMarker === item.id_user ? (
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <div>{item.username}</div>
                  </InfoWindow>
                ) : null}
              </Marker>
            ))}
          </GoogleMap>
          <div className="d-grid gap-2 pt-4">
            <button
              className="btn btn-primary"
              onClick={() => map.panTo(center)}
            >
              Centrar
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cobertura;
