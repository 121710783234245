import { Link, useNavigate } from "react-router-dom";

function GoBack(props) {
  const navigate = useNavigate();
  return (
    <Link className="btn px-0" onClick={() => navigate(-1)} to="">
      <i className="bi bi-arrow-left-short fa-3x mx-1 bg-white rounded-circle"></i>
    </Link>
  );
}

export default GoBack;
