import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useState } from "react";
import GoBack from "../components/GoBack";
import AuthService from "../services/Auth";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

const validationSchema = yup.object().shape({
  // username: yup
  //   .string()
  //   .transform((currentValue) => currentValue.split(" ").join(""))
  //   .min(2, "Campo minimo de 2 caracteres")
  //   .max(20, "Campo máximo de 20 caracteres")
  //   .required("Campo obligatorio"),
  email: yup
    .string()
    .email("Formato de mail invalido")
    .required("Campo obligatorio"),
  password: yup
    .string()
    .required("Campo obligatorio")
    .min(5, "Campo minimo de 5 caracteres"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Las contraseñas deben coincidir"),
});

function Registro(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [errorRegister, setErrorRegister] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    navigate(-1);
    setShow(false);
  };

  const onSubmit = async (data) => {
    // console.log("Data: ", data);
    setLoading(true);
    // registerWithFirebase();
    registerWithMysql(data);
  };

  const registerWithMysql = async (data) => {
    console.log("Data enviada", data);
    const rta = await AuthService.registerWithMysql({
      data,
    });
    console.log("exito register ", rta);
    if (rta.success) {
      setShow(true);
      //   console.log("exitooooooooo");
    } else {
      setErrorRegister(rta.msg);
    }

    // setErrorLogin(true);
    setLoading(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Registro correcto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Esta acción requiere una verificación de correo. Por favor revisa tu
          buzón de correo y sigue las instrucciones enviadas
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
      {loading ? <Loader /> : ""}
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 arrow">
            <GoBack />
          </div>
          <div className="col-12 py-5">
            <div className="">
              <h2 className="">Crear nueva cuenta</h2>
              <p>Por favor, completa los datos solicitados</p>
            </div>
            {/* <h2 className="text-center h4">Iniciar sesión</h2> */}
            {/* {errorRegister && <p className="text-danger">{errorRegister}</p>} */}
            {errorRegister && (
              <div className="alert alert-danger row" role="alert">
                <div className="col-10">
                  <i className="bi bi-x-octagon fa-2x pe-2"></i> {errorRegister}
                </div>
              </div>
            )}
            <Form
              action="#"
              method="post"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <Form.Group className="mb-3" controlId="email">
                <FloatingLabel
                  controlId="floatingInputEmail"
                  label="Email"
                  className="mb-3"
                >
                  <Form.Control
                    type="email"
                    placeholder="Escribe tu mail"
                    name="email"
                    {...register("email")}
                  />
                  {errors.email && (
                    <div className="alert alert-danger" role="alert">
                      {errors.email.message}
                    </div>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="password">
                <FloatingLabel
                  controlId="floatingInputPassword"
                  label="Contraseña"
                  className="mb-3"
                >
                  <Form.Control
                    type="password"
                    placeholder="Ingresa una contraseña"
                    name="password"
                    {...register("password")}
                  />
                  {errors.password && (
                    <div className="alert alert-danger" role="alert">
                      {errors.password.message}
                    </div>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="password_confirmation">
                <FloatingLabel
                  controlId="floatingInputCPassword"
                  label="Confirmar contraseña"
                  className="mb-3"
                >
                  <Form.Control
                    type="password"
                    placeholder="Confirma la contraseña"
                    name="password_confirmation"
                    {...register("password_confirmation")}
                  />
                  {errors.password_confirmation && (
                    <div className="alert alert-danger" role="alert">
                      {errors.password_confirmation.message}
                    </div>
                  )}
                </FloatingLabel>
              </Form.Group>

              <div className="d-grid gap-2 py-2">
                <Button variant="danger" size="lg" type="submit">
                  Registrarse
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <div className="text-center my-5">
          <p>
            ¿Ya eres usuario?
            <Link to="/" className="text-blue">
              Iniciar sesión
            </Link>
          </p>
        </div>
        <div className="row p-0">
          <div className="col-12 p-0">
            <div className="background"></div>
            <div className="text-center">
              {/* <p>o inicia sesion con</p> */}
              {/* <div className="mb-1">
                  <i className="bi bi-facebook fa-2x px-3"></i>
                  <i className="bi bi-google fa-2x px-3"></i>
                </div> */}
              {/* <p>¿Aún no ténes una cuenta?</p>
                <Link to="/registro">Registrate</Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Registro;
