import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GoBack from "../components/GoBack";
import { IMAGE_PATH } from "../constants/Constants";
import UseAuthContext from "../hooks/UseAuthContext";
import ContraofertasService from "../services/Contraofertas";
import moment from "moment";
import "moment/locale/es";
import { Modal, Button } from "react-bootstrap";
import Loader from "../components/Loader";

function ContraofertasUsuario() {
  const { AuthConsumer } = UseAuthContext();
  console.log("AuthConsumer", AuthConsumer);
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [contraofertas, setContraofertas] = useState([]);
  const [counter, setCounter] = useState(0);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setCounter(counter + 1);
    setShow(false);
  };

  async function contraofertasAll() {
    const response = await ContraofertasService.getUserSender(
      AuthConsumer.user.id_user
      // 5
    );
    console.log("contraofertasAll", response.data);
    if (response.success) {
      setContraofertas(response.data);
    } else {
      setContraofertas("");
    }
    setLoading(false);
  }

  console.log("contraofertas", contraofertas);

  useEffect(() => {
    contraofertasAll();
    // console.log("ContraofertasService.get(id) =", contraofertas);
  }, [counter]);

  async function deleteCounteroffer(id) {
    // console.log("id eliminar = ", id);
    const success = await ContraofertasService.delete(id);
    // console.log("Éxito al eliminar: ", success);
    if (success) {
      setShow(true);
      setCounter(counter + 1);
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Contraoferta</Modal.Title>
        </Modal.Header>
        <Modal.Body>Se elimino correctamente la contraoferta</Modal.Body>
        <Modal.Footer>
          <Button variant="success btn-lg" onClick={handleClose}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
      {loading ? (
        <Loader />
      ) : (
        <div className="row bg-white ">
          <div className="container min-vh-view">
            <div className="row justify-content-center">
              <div className="col-10 arrow">
                <GoBack />
              </div>
              <div className="col-10">
                {contraofertas.length >= 1 ? (
                  <>
                    {contraofertas.map((item) => (
                      <div className="" key={item.id_counteroffer}>
                        <div className="card border-0">
                          <div className="row g-0">
                            <div className="col-2 m-auto">
                              <img
                                src={
                                  item.publications.image
                                    ? IMAGE_PATH +
                                      "/productos/" +
                                      item.publications.image
                                    : IMAGE_PATH + "/empty.png"
                                }
                                className="img-fluid rounded-start"
                                alt="sin_imagen"
                              />
                            </div>

                            <div className="col-10 p-3">
                              <div className="btn-light text-decoration-none">
                                <div className="card-body p-1">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                      <h3 className="card-title h5 d-inline">
                                        {item.publications.product}
                                      </h3>
                                    </div>
                                  </div>

                                  <div>
                                    <div className="card-text h6 fw-normal">
                                      <p className="small">
                                        contraofertaste el{" "}
                                        {moment(item.created_at).format(
                                          "D MMMM, h:mm a"
                                        )}
                                      </p>

                                      <p>
                                        ${item.price} de{" "}
                                        <span className="text-decoration-line-through">
                                          ${item.publications.price}
                                        </span>{" "}
                                      </p>
                                    </div>

                                    <button
                                      className="btn btn-danger me-2"
                                      onClick={() =>
                                        deleteCounteroffer(item.id_counteroffer)
                                      }
                                    >
                                      Eliminar
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="py-4 px-2">
                      <div className="text-center py-2">
                        <i className="bi bi-arrow-left-right fa-4x"></i>
                      </div>

                      <h3 className="card-title h6 fw-normal text-center">
                        No hay actualmente ninguna contaoferta generada
                      </h3>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ContraofertasUsuario;
