import { API_HOST } from "../constants/Constants";

const NotificacionesService = {
  async getUser(id) {
    const response = await fetch(`${API_HOST}/notifications/user/${id}`);
    const jsonData = await response.json();
    return jsonData;
  },
  async getUserInactive(id) {
    const response = await fetch(
      `${API_HOST}/notifications/user/inactive/${id}`
    );
    const jsonData = await response.json();
    return jsonData;
  },
  async all() {
    const response = await fetch(`${API_HOST}/notifications`);
    const jsonData = await response.json();
    return jsonData;
  },
  async create(data) {
    const response = await fetch(`${API_HOST}/notifications`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    });
    const parsed = await response.json();
    return parsed;
  },
};

export default NotificacionesService;
