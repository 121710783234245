import { useEffect, useState } from "react";
import { Form, Button, Modal, FloatingLabel } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import PublicacionesService from "../services/Publicaciones";
import GoBack from "./GoBack";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ContraofertasService from "../services/Contraofertas";
import Loader from "./Loader";
import UseAuthContext from "../hooks/UseAuthContext";

const validationSchema = yup.object().shape({
  price: yup
    .number()
    .typeError("Campo obligatorio")
    .required("Campo obligatorio")
    .min(1, "Valor minimo mayor a 1")
    .max(999999, "Valor maximo 999999")
    .positive()
    .integer("Campo de numero entero"),
});

function Contraofertar(props) {
  const { AuthConsumer } = UseAuthContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const [publicacion, setPublicacion] = useState({
    product: null,
    image: null,
    description: null,
    created: null,
    first_name: null,
    price: null,
    username: null,
    id_categoria: null,
    categorias: {
      name: null,
    },
    users: {
      username: null,
      first_name: null,
    },
  });
  const [loading, setLoading] = useState(true);
  console.log(id);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [show, setShow] = useState(false);
  const handleClose = () => {
    navigate(-1);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    (async () => {
      const Publicacion = await PublicacionesService.get(id);
      console.log("PublicacionesService.get(id) =", Publicacion.data);
      setPublicacion(Publicacion.data);
      setLoading(false);
    })();
  }, []);

  function onSubmit(data) {
    setLoading(true);
    console.log("La data recibida por RHF es: ", data);
    ContraofertasService.create({
      ...data,
      id_publication: publicacion.id_publication,
      id_user_sender: AuthConsumer.user.id_user,
      id_user_receiver: publicacion.id_user,
      description: null,
      state: 1,
    }).then((data) => {
      console.log("success?s ", data);
      if (data.success) {
        setLoading(false);
        setShow(true);
      }
    });
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header>
              <Modal.Title>Contraoferta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Se ha generado correctamente la contraoferta
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success btn-lg" onClick={handleClose}>
                Continuar
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="row bg-white ">
            <div className="container min-vh-100">
              <div className="row justify-content-center">
                <div className="col-10">
                  <GoBack />
                </div>
                <div className="col-10">
                  <div className="py-3">
                    <h2>Contraofertar</h2>
                    <p>
                      Asigne un precio como valor para contraofertar la
                      solicitud formulada, recuerde que solo se puede realizar
                      una contraoferta por publicación
                    </p>
                  </div>

                  <Form action="#" onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3" controlId="username">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Usuario"
                        className="mb-3 notRequired"
                      >
                        <Form.Control
                          type="text"
                          value={publicacion.users.username}
                          disabled
                        />
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="producto">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Producto"
                        className="mb-3 notRequired"
                      >
                        <Form.Control
                          type="text"
                          value={publicacion.product}
                          disabled
                        />
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3 " controlId="precio-pedido">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Precio a pagar"
                        className="mb-3 notRequired"
                      >
                        <Form.Control
                          type="text"
                          value={`$ ${publicacion.price}`}
                          disabled
                        />
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="precio-contraofertado"
                    >
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Precio a contraofertar"
                        className="mb-3"
                      >
                        <Form.Control
                          type="number"
                          name="price"
                          {...register("price")}
                        />
                        {errors.price && (
                          <div className="alert alert-danger" role="alert">
                            {errors.price.message}
                          </div>
                        )}
                      </FloatingLabel>
                    </Form.Group>
                    <div className="d-grid gap-2">
                      <Button variant="success" size="lg" type="submit">
                        Enviar
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Contraofertar;
