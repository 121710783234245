// import logo from "./logo.svg";

import "./App.css";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar.js";
import Home from "./views/Home";
import PublicacionesService from "./services/Publicaciones";
import Categorias from "./views/Categorias";
import Publicaciones from "./views/Publicaciones";
import PublicacionesNueva from "./views/PublicacionesNueva";
import Perfil from "./views/Perfil";
import Login from "./views/Login";
import PublicacionesCategoria from "./views/PublicacionesCategoria";
import CategoriasLista from "./views/CategoriasLista";
import PublicacionesDetalle from "./views/PublicacionesDetalle";
import Notificaciones from "./views/Notificaciones";
import PublicacionesUsuario from "./views/PublicacionesUsuario";
import PerfilEditar from "./views/PerfilEditar";
import Footer from "./components/Footer";
import Contraofertas from "./views/Contraofertas";
import AuthService from "./services/Auth";
import Autenticado from "./components/Autenticado";
import Denuncias from "./components/Denuncias";
import Contraofertar from "./components/Contraofertar";
import Registro from "./views/Registro";
import UserProvider from "./components/UserProvider";
import PublicacionesEditar from "./views/PublicacionesEditar";
import Acuerdo from "./views/Acuerdo";
import Usuarios from "./views/Usuarios";
import UsuariosDetalle from "./views/UsuariosDetalle";
import UseAuth from "./hooks/UseAuth";
import UseAuthContext from "./hooks/UseAuthContext";
import RouteAuth from "./components/RouteAuth";
import Cobertura from "./views/Cobertura";
import Busqueda from "./views/Busqueda";
import FormVendedor from "./views/FormVendedor";
import Solicitudes from "./views/Solicitudes";
import SolicitudesDetalle from "./views/SolicitudesDetalle";
import ContraofertasUsuario from "./views/ContraofertasUsuario";
import PerfilClave from "./views/PerfilClave";
import Terminos from "./views/Terminos";
import RestablecerClave from "./views/RestablecerClave";
import SolicitudesDenegar from "./views/SolicitudesDenegar";

function App() {
  const auth = UseAuth();
  const { AuthContext } = UseAuthContext();
  const location = useLocation();
  const { pathname } = location;
  const getLocation = pathname.split("/");
  console.log("getLocation Principal = ", getLocation.length);

  return (
    <AuthContext.Provider
      value={{
        user: auth.user,
        logged: auth.logged,
      }}
    >
      <div className="App">
        {/* <UserProvider> */}
        {/* {authState.logged ? <Navigate to="/login" /> : ""} */}
        {auth.logged ? (
          getLocation.length >= 3 ||
          getLocation[1] === "cobertura" ||
          getLocation[1] === "publicaciones" ||
          getLocation[1] === "usuarios" ||
          getLocation[1] === "notificaciones" ||
          getLocation[1] === "acuerdo" ||
          getLocation[1] === "formVendedor" ? (
            ""
          ) : (
            <Navbar />
          )
        ) : (
          ""
        )}

        <main>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12 front">
                  <Routes>
                    <Route
                      path="/"
                      exact
                      element={
                        // <Login onLogin={handleLogin} logged={authState} />
                        <Login />
                      }
                    />
                    <Route path="/registro" element={<Registro />} />

                    <Route
                      path="/home"
                      exact
                      element={
                        <RouteAuth>
                          <Home />
                        </RouteAuth>
                      }
                    ></Route>

                    <Route
                      path="/publicaciones/:id"
                      exact
                      element={
                        <RouteAuth>
                          <PublicacionesDetalle />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/publicaciones/nueva"
                      exact
                      element={
                        <RouteAuth>
                          <PublicacionesNueva />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/publicaciones/usuario/:id"
                      exact
                      element={
                        <RouteAuth>
                          <PublicacionesUsuario />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/perfil/:id/editar"
                      exact
                      element={
                        <RouteAuth>
                          <PerfilEditar />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/perfil/:id/clave"
                      exact
                      element={
                        <RouteAuth>
                          <PerfilClave />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/publicaciones"
                      exact
                      element={
                        <RouteAuth>
                          <Publicaciones />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/usuarios"
                      element={
                        <RouteAuth>
                          <Usuarios />
                        </RouteAuth>
                      }
                    />

                    <Route
                      path="/categorias"
                      exact
                      element={
                        <RouteAuth>
                          <Categorias />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/categorias/:id"
                      exact
                      element={
                        <RouteAuth>
                          <CategoriasLista />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/usuarios/:id"
                      exact
                      element={
                        <RouteAuth>
                          <UsuariosDetalle />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/notificaciones"
                      exact
                      element={
                        <RouteAuth>
                          <Notificaciones />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/contraofertas"
                      exact
                      element={
                        <RouteAuth>
                          <Contraofertas />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/perfil"
                      exact
                      element={
                        <RouteAuth>
                          <Perfil />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/publicaciones/:id/denunciar"
                      exact
                      element={
                        <RouteAuth>
                          <Denuncias />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/publicaciones/:id/editar"
                      exact
                      element={
                        <RouteAuth>
                          <PublicacionesEditar />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/publicaciones/:id/contraofertar"
                      exact
                      element={
                        <RouteAuth>
                          <Contraofertar />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/acuerdo"
                      exact
                      element={
                        <RouteAuth>
                          <Acuerdo />
                        </RouteAuth>
                      }
                    />

                    <Route
                      path="/cobertura"
                      exact
                      element={
                        <RouteAuth>
                          <Cobertura />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/busqueda"
                      exact
                      element={
                        <RouteAuth>
                          <Busqueda />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/formVendedor"
                      exact
                      element={
                        <RouteAuth>
                          <FormVendedor />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/solicitudes"
                      exact
                      element={
                        <RouteAuth>
                          <Solicitudes />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/solicitudes/:id"
                      exact
                      element={
                        <RouteAuth>
                          <SolicitudesDetalle />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/solicitudes/:id/denegar"
                      exact
                      element={
                        <RouteAuth>
                          <SolicitudesDenegar />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/contraofertas/usuario/:id"
                      exact
                      element={
                        <RouteAuth>
                          <ContraofertasUsuario />
                        </RouteAuth>
                      }
                    />
                    <Route
                      path="/restablecerClave"
                      exact
                      element={<RestablecerClave />}
                    />
                    <Route path="/terminos" exact element={<Terminos />} />
                  </Routes>
                </div>
              </div>
            </div>
          </section>
        </main>
        {auth.logged ? (
          getLocation.length >= 3 ||
          getLocation[1] === "cobertura" ||
          getLocation[1] === "publicaciones" ||
          getLocation[1] === "usuarios" ||
          getLocation[1] === "notificaciones" ||
          getLocation[1] === "acuerdo" ||
          getLocation[1] === "formVendedor" ? (
            ""
          ) : (
            <Footer />
          )
        ) : (
          ""
        )}
      </div>
    </AuthContext.Provider>
  );
}

export default App;
