import GoBack from "../components/GoBack";
import { useState } from "react";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import DenunciasServices from "../services/Denuncias";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import UseAuthContext from "../hooks/UseAuthContext";
import NotificacionesService from "../services/Notificaciones";
import Loader from "../components/Loader";

function SolicitudesDenegar() {
  const { AuthConsumer } = UseAuthContext();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const validationSchema = yup.object().shape({
    description: yup
      .string()
      .trim("El campo no puede incluir espacios iniciales y finales")
      .strict(true)
      .required("Campo obligatorio")
      .min(2, "Campo minimo de 2 caracteres"),
  });
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    navigate("/perfil");
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  function onSubmit(data) {
    // setLoading(true);
    console.log("La data recibida por RHF es: ", data);
    NotificacionesService.create({
      ...data,
      id_vendor: id,
      id_user: AuthConsumer.user.id_user,
    }).then((data) => {
      console.log("success?s ", data);
      if (data.success) {
        setLoading(false);
        setShow(true);
      }
    });
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Solicitud enviada</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Se informará al usuario el motivo brindado por el cual no puede
          avanzar con el proceso de cambio de rol
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success btn-lg" onClick={handleClose}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
      {loading ? (
        <Loader />
      ) : (
        <div className="row bg-white ">
          <div className="container min-vh-view">
            <div className="row justify-content-center">
              <div className="col-10 arrow">
                <GoBack />
              </div>
              <div className="col-10">
                <h3>Enviar mensaje</h3>
                <p className="text-description">
                  Informe el motivo por el cual la solicitud brindada no cumple
                  los requisitos para cambiar a rol vendedor
                </p>
                <Form action="" onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group className="pb-3" controlId="mensaje">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Descripción"
                      className="mb-3"
                    >
                      <Form.Control
                        as="textarea"
                        rows={10}
                        className="no-resize"
                        placeholder="Ingrese los detalles"
                        name="description"
                        style={{ height: "200px" }}
                        {...register("description")}
                      />
                      {errors.description && (
                        <div className="alert alert-danger" role="alert">
                          {errors.description.message}
                        </div>
                      )}
                    </FloatingLabel>
                  </Form.Group>

                  <div className="d-grid gap-2">
                    <Button variant="success" size="lg" type="submit">
                      Enviar
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default SolicitudesDenegar;
