import GoBack from "../components/GoBack";

function UsuariosDetalle(props) {
  return (
    <>
      <div className="row bg-white ">
        <div className="container min-vh-view">
          <div className="row justify-content-center">
            <div className="col-10 arrow">
              <GoBack />
            </div>

            <div className="col-10"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UsuariosDetalle;
