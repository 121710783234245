import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UseAuthContext from "../hooks/UseAuthContext";

function Home() {
  // console.log("Homeeeeeeeeeeeeeeeeeeee");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const { AuthConsumer } = UseAuthContext();
  console.log("AuthConsumer ", AuthConsumer);

  return (
    <>
      <div className="row bg-white d-flex justify-content-center">
        <div className="col-10 py-4">
          <div className="py-4">
            <h2>¡Te damos la bienvenida {AuthConsumer.user.username}!</h2>
          </div>
          <h3 className="h5">
            Compra tu producto que tanto quieres a un precio más bajo
          </h3>
          <p className="pt-2">
            Contraoferta es la aplicación donde puedes publicar una búsqueda de
            lo que necesites y obtenerlo a un precio más bajo que en las tiendas
          </p>
        </div>
      </div>
    </>
  );
}

export default Home;
