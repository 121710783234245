import { API_HOST } from "../constants/Constants.js";

const CategoriasService = {
  all() {
    return fetch(`${API_HOST}/categories`)
      .then((response) => response.json())
      .then((parsed) => parsed);
  },
  async get(id) {
    const response = await fetch(`${API_HOST}/categories/${id}`);
    const jsonData = await response.json();
    return jsonData;
  },
};
export default CategoriasService;
