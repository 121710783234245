import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GoBack from "../components/GoBack";
import Loader from "../components/Loader";
import { IMAGE_PATH } from "../constants/Constants";
import UsuariosService from "../services/Usuarios";

function Usuarios(props) {
  const [usuarios, setusuarios] = useState([]);
  const [loading, setLoading] = useState(true);

  async function cargarUsuarios() {
    const data = await UsuariosService.all();
    // console.log("cargarUsuarios: ", data);
    setusuarios(data);
    setLoading(false);
  }

  useEffect(() => {
    cargarUsuarios();
  }, []);

  return (
    <>
      {loading ? <Loader /> : <></>}
      <div className="row bg-white ">
        <div className="container min-vh-view">
          <div className="row justify-content-center">
            <div className="col-10 arrow">
              <GoBack />
            </div>

            <div className="col-10">
              <ul className="list-group align-items-center">
                {usuarios.map((item) => (
                  <li className="pb-3" key={item.id_user}>
                    <Link
                      to={`../perfil/${item.id_user}/editar`}
                      className={`row align-items-center ${
                        item.state ? "" : "review"
                      }`}
                    >
                      <div className="col-3 m-auto">
                        <img
                          src={
                            item.avatar
                              ? IMAGE_PATH + "/usuarios/" + item.avatar
                              : IMAGE_PATH + "/empty.png"
                          }
                          alt={item.image}
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="col-7">
                        <h3 className="h6">{item.first_name}</h3>
                      </div>
                      <div className="col-2">
                        <i className="bi bi-arrow-right-short fa-2x"></i>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Usuarios;
