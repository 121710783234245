import { API_HOST } from "../constants/Constants.js";

const VendedoresService = {
  all() {
    return fetch(`${API_HOST}/vendors`)
      .then((response) => response.json())
      .then((parsed) => parsed.data);
  },
  async get(id) {
    const response = await fetch(`${API_HOST}/vendors/${id}`);
    const jsonData = await response.json();
    return jsonData;
  },
  async getUser(id) {
    const response = await fetch(`${API_HOST}/vendors/user/${id}`);
    const jsonData = await response.json();
    return jsonData;
  },
  async rol(id) {
    const response = await fetch(`${API_HOST}/vendors/${id}`, {
      method: "POST",
      body: JSON.stringify(id),
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    });
    const jsonData = await response.json();
    return jsonData;
  },
  async getCategory(id) {
    const response = await fetch(`${API_HOST}/vendors/category/${id}`);
    const jsonData = await response.json();
    return jsonData;
  },
  async create(data) {
    const response = await fetch(`${API_HOST}/vendors`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    });
    const parsed = await response.json();
    return parsed;
  },
  async edit(data) {
    const response = await fetch(
      `${API_HOST}/usuarios/${data.id_publication}/edit`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
        },
      }
    );
    const parsed = await response.json();
    return parsed;
  },
  async delete(id) {
    const response = await fetch(`${API_HOST}/vendors/${id}`, {
      method: "DELETE",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    });
    const parsed = await response.json();
    // empresas = empresas.filter(empresa => empresa.id_empresa !== id);
    return parsed.success;
  },
};

export default VendedoresService;
