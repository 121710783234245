import { API_HOST } from "../constants/Constants.js";

const UsuariosService = {
  all() {
    return fetch(`${API_HOST}/users`)
      .then((response) => response.json())
      .then((parsed) => parsed.data);
  },
  async get(id) {
    const response = await fetch(`${API_HOST}/users/${id}`);
    const jsonData = await response.json();
    return jsonData;
  },
  async getUser(id) {
    const response = await fetch(`${API_HOST}/users/user/${id}`);
    const jsonData = await response.json();
    return jsonData;
  },
  async state(id) {
    const response = await fetch(`${API_HOST}/users/state/${id}`, {
      method: "POST",
      body: JSON.stringify(id),
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    });
    const jsonData = await response.json();
    return jsonData;
  },
  async getCategory(id) {
    const response = await fetch(`${API_HOST}/users/category/${id}`);
    const jsonData = await response.json();
    return jsonData;
  },
  async create(data) {
    const response = await fetch(`${API_HOST}/users`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    });
    const parsed = await response.json();
    return parsed;
  },
  async edit(data) {
    const response = await fetch(
      `${API_HOST}/usuarios/${data.id_publication}/edit`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
        },
      }
    );
    const parsed = await response.json();
    return parsed;
  },
  async delete(id) {
    const response = await fetch(`${API_HOST}/users/${id}`, {
      method: "DELETE",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    });
    const parsed = await response.json();
    // empresas = empresas.filter(empresa => empresa.id_empresa !== id);
    return parsed.success;
  },
};

export default UsuariosService;
