import { useEffect, useState } from "react";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import GoBack from "../components/GoBack";
import Loader from "../components/Loader";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import UseAuthContext from "../hooks/UseAuthContext";
import PerfilService from "../services/Perfil";
import VendedoresService from "../services/Vendedores";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object().shape({
  activity: yup
    .string()
    .required("Campo obligatorio")
    .min(2, "Campo minimo de 2 caracteres"),
  phone: yup
    .number()
    .typeError("Campo obligatorio")
    .required("Campo obligatorio")
    .min(10000000, "Valor minimo 8 numeros")
    .max(9999999999, "Valor maximo 12 numeros"),
  address: yup
    .string()
    .required("Campo obligatorio")
    .min(8, "Campo minimo de 2 caracteres"),
  floor: yup.string(),
  web_page: yup.string(),
  postal_code: yup
    .number()
    .typeError("Campo obligatorio")
    .required("Campo obligatorio")
    .min(100, "Valor minimo 3 numeros")
    .max(9999, "Valor maximo 5 numeros"),
});

function FormVendedor() {
  const { AuthConsumer } = UseAuthContext();
  const navigate = useNavigate();
  console.log("AuthConsumer", AuthConsumer);
  const [usuario, setUsuario] = useState({
    activity: null,
    first_name: null,
    last_name: null,
  });
  const [loading, setLoading] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [show, setShow] = useState(false);
  const handleClose = () => {
    navigate(-1);
    setShow(false);
  };

  function onSubmit(data) {
    setLoading(true);
    // console.log("onSubmit ", data);
    VendedoresService.create({
      ...data,
      id_user: AuthConsumer.user.id_user,
    }).then((data) => {
      console.log("success?s ", data);
      if (data.success) {
        setLoading(false);
        setShow(true);
      }
    });
  }

  async function cargarPerfil() {
    // console.log("perfil id ", AuthConsumer.user.id_user);
    const data = await PerfilService.get(AuthConsumer.user.id_user);
    // console.log(data);
    setValue("address", data.address);
    setLoading(false);
    // console.log("perfil data ", usuario);
  }

  useEffect(() => {
    cargarPerfil();
  }, [loading]);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Formulario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Se ha generado correctamente el formulario para cambiar de categoria a
          vendedor, una vez verificado los datos podras verificar el cambio.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
      {loading ? <Loader /> : <></>}
      <div className="row bg-white ">
        <div className="container min-vh-view">
          <div className="row justify-content-center">
            <div className="col-10 arrow">
              <GoBack />
            </div>
            <div className="col-10">
              <h2>Solicitud para vendedor</h2>
              <p>
                Completa todos los campos requeridos para solicitar el cambio de
                nivel
              </p>
              <div className="mb-5">
                <Form
                  action="#"
                  method="post"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <Form.Group className="mb-3" controlId="price">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Rubro"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="price"
                        placeholder="Ingresa tu rubro"
                        {...register("activity")}
                      />
                      {errors.activity && (
                        <div className="alert alert-danger" role="alert">
                          {errors.activity.message}
                        </div>
                      )}
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="phone">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Telefono"
                      className="mb-3"
                    >
                      <Form.Control
                        type="number"
                        name="phone"
                        placeholder="Ingresa tu telefono"
                        {...register("phone")}
                      />
                      {errors.phone && (
                        <div className="alert alert-danger" role="alert">
                          {errors.phone.message}
                        </div>
                      )}
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="address">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Dirección"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="address"
                        placeholder="Ingresa tu dirección completa"
                        {...register("address")}
                      />
                      {errors.address && (
                        <div className="alert alert-danger" role="alert">
                          {errors.address.message}
                        </div>
                      )}
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="floor">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Piso"
                      className="mb-3 notRequired"
                    >
                      <Form.Control
                        type="text"
                        name="floor"
                        placeholder="Ingresa tu piso"
                        {...register("floor")}
                      />
                      {errors.floor && (
                        <div className="alert alert-danger" role="alert">
                          {errors.floor.message}
                        </div>
                      )}
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="postal_code">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Codigo postal"
                      className="mb-3"
                    >
                      <Form.Control
                        type="number"
                        name="postal_code"
                        placeholder="Ingresa tu codigo postal"
                        {...register("postal_code")}
                      />
                      {errors.postal_code && (
                        <div className="alert alert-danger" role="alert">
                          {errors.postal_code.message}
                        </div>
                      )}
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="web_page">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Pagina web"
                      className="mb-3 notRequired"
                    >
                      <Form.Control
                        type="text"
                        name="web_page"
                        placeholder="Ingresa tu pagina web"
                        {...register("web_page")}
                      />
                      {errors.web_page && (
                        <div className="alert alert-danger" role="alert">
                          {errors.web_page.message}
                        </div>
                      )}
                    </FloatingLabel>
                  </Form.Group>

                  <div className="d-flex justify-content-end">
                    <p className="requiredBefore small">Campos obligatorios</p>
                  </div>
                  <div className="d-grid gap-2 py-2">
                    <Button variant="success" size="lg" type="submit">
                      Enviar
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FormVendedor;
