import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useState } from "react";
import GoBack from "../components/GoBack";
import AuthService from "../services/Auth";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Formato de mail invalido")
    .required("Campo obligatorio"),
});

function RestablecerClave() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [errorRegister, setErrorRegister] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    navigate(-1);
    setShow(false);
  };

  const restorePassword = async (data) => {
    console.log("Data enviada", data);
    const rta = await AuthService.restore({
      data,
    });
    console.log("exito register ", rta);
    if (rta.success) {
      setShow(true);
      //   console.log("exitooooooooo");
    } else {
      setErrorRegister(rta.msg);
    }

    // setErrorLogin(true);
    setLoading(false);
  };

  const onSubmit = async (data) => {
    // console.log("Data: ", data);
    setLoading(true);
    // registerWithFirebase();
    restorePassword(data);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Restablecer acceso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Se envió un correo con la información de acceso. Por favor revisa tu
          buzón de correo y sigue las instrucciones enviadas
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success btn-lg" onClick={handleClose}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="row">
        <div className="container min-vh-view">
          <div className="row justify-content-center">
            <div className="col-10 arrow">
              <GoBack />
            </div>

            <div className="col-10 py-5">
              <h2>Restablecer la contraseña</h2>
              <p className="text-description">
                Ingrese el correo electrónico asociado con su cuenta y le
                enviaremos un correo electrónico con instrucciones para
                restablecer su contraseña
              </p>
              {errorRegister && (
                <div className="alert alert-danger row" role="alert">
                  <div className="col-10">
                    <i className="bi bi-x-octagon fa-2x pe-2"></i>{" "}
                    {errorRegister}
                  </div>
                </div>
              )}
              <Form
                action="#"
                method="post"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                <Form.Group className="mb-3" controlId="email">
                  <FloatingLabel
                    controlId="floatingInputEmail"
                    label="Email"
                    className="mb-3"
                  >
                    <Form.Control
                      type="email"
                      placeholder="Escribe tu mail"
                      name="email"
                      {...register("email")}
                    />
                    {errors.email && (
                      <div className="alert alert-danger" role="alert">
                        {errors.email.message}
                      </div>
                    )}
                  </FloatingLabel>
                </Form.Group>

                <div className="d-grid gap-2 py-2">
                  <Button variant="danger" size="lg" type="submit">
                    Enviar instrucciones
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RestablecerClave;
