import { useState } from "react";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import DenunciasServices from "../services/Denuncias";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import GoBack from "./GoBack";
import Loader from "./Loader";
import UseAuthContext from "../hooks/UseAuthContext";

function Denuncias() {
  const { AuthConsumer } = UseAuthContext();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const validationSchema = yup.object().shape({
    description: yup
      .string()
      .trim("El campo no puede incluir espacios iniciales y finales")
      .strict(true)
      .required("Campo obligatorio")
      .min(2, "Campo minimo de 2 caracteres"),
  });
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    navigate(-1);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  function onSubmit(data) {
    setLoading(true);
    console.log("La data recibida por RHF es: ", data);
    DenunciasServices.create({
      ...data,
      id_publication: id,
      id_user: AuthConsumer.user.id_user,
    }).then((data) => {
      console.log("success?s ", data);
      if (data.success) {
        setLoading(false);
        setShow(true);
      }
    });
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header>
              <Modal.Title>Reportar</Modal.Title>
            </Modal.Header>
            <Modal.Body>Se genero correctamente el reporte</Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={handleClose}>
                Continuar
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="row bg-white ">
            <div className="container min-vh-100">
              <div className="row justify-content-center">
                <div className="col-10">
                  <GoBack />
                </div>
                <div className="col-10">
                  <div className="my-4">
                    <h2 className="h4">Reportar publicación</h2>
                    <p>
                      Informanos todos los detalles del motivo por el cual la
                      publicación no es esta correctamente formulada, una vez
                      generada la solicitud verificaremos la publicación.
                    </p>
                  </div>
                  <div>
                    <Form action="" onSubmit={handleSubmit(onSubmit)}>
                      <Form.Group className="pb-3" controlId="mensaje">
                        <FloatingLabel
                          controlId="floatingTextarea"
                          label="Descripción"
                          className="mb-3"
                        >
                          <Form.Control
                            as="textarea"
                            rows={6}
                            className="no-resize"
                            placeholder="Ingrese los detalles"
                            name="description"
                            style={{ height: "100px" }}
                            {...register("description")}
                          />
                          {errors.description && (
                            <div className="alert alert-danger" role="alert">
                              {errors.description.message}
                            </div>
                          )}
                        </FloatingLabel>
                      </Form.Group>

                      <div className="d-grid gap-2">
                        <Button variant="success" size="lg" type="submit">
                          Enviar
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Denuncias;
