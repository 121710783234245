import React, { useEffect, useState } from "react";

import PublicacionesService from "../services/Publicaciones.js";
import { useParams } from "react-router";
import { IMAGE_PATH } from "../constants/Constants.js";
import Loader from "../components/Loader.js";
import GoBack from "../components/GoBack.js";
import AuthService from "../services/Auth.js";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DenunciasService from "../services/Denuncias.js";
import ContraofertasService from "../services/Contraofertas.js";
import UseAuthContext from "../hooks/UseAuthContext.js";
import { set } from "react-hook-form";
import PerfilService from "../services/Perfil.js";

function formatNumber(number) {
  return new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
  }).format(number);
}

function PublicacionesDetalle(props) {
  const { AuthConsumer } = UseAuthContext();
  console.log("AuthConsumer = ", AuthConsumer);
  const navigate = useNavigate();
  const [user, setUser] = useState(AuthService.getUser());
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [publicacion, setPublicacion] = useState({
    product: null,
    image: null,
    description: null,
    created: null,
    first_name: null,
    price: null,
    username: null,
    id_categoria: null,
    categorias: {
      name: null,
    },
  });
  const [usuario, setUsuario] = useState();
  const [denuncia, setDenuncia] = useState(false);
  const [denunciaPublicacion, setDenunciaPublicacion] = useState(false);
  const [contraoferta, setContraoferta] = useState(false);
  // const [denuncia, setDenuncia] = useState(false);
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => {
    setShowDelete(false);
    navigate(-1);
  };
  const handleShowDelete = () => {
    setShowDelete(true);
  };
  const [showDisable, setShowDisable] = useState(false);
  const handleCloseDisable = () => {
    setShowDisable(false);
    navigate(-1);
  };

  console.log("id ", id);

  async function cargarPerfil() {
    // console.log("perfil id ", AuthConsumer.user.id_user);
    const data = await PerfilService.get(AuthConsumer.user.id_user);
    setUsuario(data);
    // console.log("perfil data ", usuario);
  }

  useEffect(() => {
    cargarPerfil();
  }, []);

  useEffect(() => {
    if (loading) {
      (async () => {
        const Publicacion = await PublicacionesService.get(id);
        console.log("PublicacionesService.get(id) =", Publicacion.data);
        setPublicacion(Publicacion.data);
        console.log("publicacion ", publicacion);

        setLoading(false);
      })();
    }
  }, [loading]);

  useEffect(() => {
    (async () => {
      const Denuncia = await DenunciasService.getPublicacion(id);
      console.log("DenunciasService.get(id) =", Denuncia.data.length);
      if (Denuncia.data != null && Denuncia.data.length >= 5) {
        setDenunciaPublicacion(true);
      }
      Denuncia.data.forEach((data) => {
        if (data.id_user === AuthConsumer.user.id_user) {
          setDenuncia(true);
        }
      });

      if (Denuncia.data == null) {
        setDenuncia(false);
      }
      // if(Denuncia)
      // setLoading(false);
    })();
  }, [loading]);

  useEffect(() => {
    (async () => {
      const response = await ContraofertasService.getPublicacion(id);
      console.log("ContraofertasService.getPublicacion(id) =", response.data);
      const data = response.data.filter(
        (data) => data.id_user_sender === user.id_user
      ).length;
      console.log("dataRespuesta", data);
      if (data) {
        setContraoferta(true);
      }
      // if (
      //   contraoferta.data != null &&
      //   contraoferta.data.id_user == AuthConsumer.user.id_user
      // ) {
      //   setContraoferta(true);
      // }
      // setLoading(false);
    })();
  }, [loading]);

  // async function reportes() {
  //   const response = await DenunciasService.getPublicacion(
  //     publicacion.id
  //     // 5
  //   );
  //   console.log("reportes", response);
  //   if (response.success) {
  //     setDenuncia(true);
  //   }
  //   setLoading(false);
  // }

  const handleDelete = async () => {
    const success = await PublicacionesService.delete(
      publicacion.id_publication
    );
    console.log("Éxito al eliminar: ", success);
    // window.location.reload(true);
    setShowDelete(true);
  };

  const handleDisable = async () => {
    const success = await PublicacionesService.state(
      publicacion.id_publication
    );
    console.log("Éxito al desactivar: ", success);
    // window.location.reload(true);
    setShowDisable(true);
  };

  return (
    <>
      <Modal show={showDelete} onHide={handleCloseDelete} centered>
        <Modal.Header
          closeButton
          closeVariant="white"
          className="bg-red border-0"
        >
          <Modal.Title>Publicación</Modal.Title>
        </Modal.Header>
        <Modal.Body>Se elimino correctamente la publicación</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleCloseDelete}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDisable} onHide={handleCloseDisable} centered>
        <Modal.Header
          closeButton
          closeVariant="white"
          className="bg-red border-0"
        >
          <Modal.Title>Publicación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Se cambio correctamente el estado de la publicación
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleCloseDisable}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="row bg-white ">
            <div
              style={{
                background: `url(${
                  publicacion.image
                    ? IMAGE_PATH + "/productos/" + publicacion.image
                    : IMAGE_PATH + "/empty.png"
                })center no-repeat`,
                backgroundSize: "cover",
                height: "230px",
                position: "absolute",
              }}
            ></div>
            <div className="container min-vh-view">
              <div className="row justify-content-center">
                <div className="col-10 arrow">
                  <GoBack />
                </div>

                <div className="col-10">
                  <div className="mb-5">
                    {denunciaPublicacion ? (
                      <div className="alert review" role="alert">
                        <i className="bi bi-exclamation-triangle px-2"></i>{" "}
                        Publicación en revisión
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      className="pb-4 position-relative"
                      style={{ height: "200px" }}
                    >
                      {/* <img
                        async
                        src={
                          publicacion.image
                            ? IMAGE_PATH + "/productos/" + publicacion.image
                            : IMAGE_PATH + "/empty.png"
                        }
                        alt={`imagen de ${publicacion.name}`}
                        className="img-fluid rounded-start w-75"
                      /> */}
                    </div>
                    <h2 className="h4">{publicacion.product}</h2>
                    <div className="row align-items-center">
                      <div className="col-2 pe-1 py-1">
                        <img
                          src={
                            publicacion.users.avatar
                              ? IMAGE_PATH +
                                "/usuarios/" +
                                publicacion.users.avatar
                              : IMAGE_PATH + "/avatar.jpg"
                          }
                          alt="avatar"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="col-10 p-0">
                        <p className=" px-2 m-0">
                          {publicacion.users.username}
                        </p>
                      </div>
                    </div>
                    <div className="py-3 text-description">
                      {publicacion.description}
                    </div>
                    <dl className="row">
                      <dt className="col-3">Categoria</dt>
                      <dd className="col-9">{publicacion.categories.name}</dd>
                      {AuthConsumer.user.id_role !== 4 ? (
                        <>
                          <dt className="col-3">Precio</dt>
                          <dd className="col-9">
                            {formatNumber(publicacion.price)}
                          </dd>
                        </>
                      ) : AuthConsumer.user.id_user === publicacion.id_user ? (
                        <>
                          <dt className="col-3">Precio</dt>
                          <dd className="col-9">
                            {formatNumber(publicacion.price)}
                          </dd>
                        </>
                      ) : (
                        ""
                      )}
                    </dl>
                    <div className="pb-3 h3 text-center">
                      {/* {formatNumber(publicacion.price)} */}
                    </div>
                    {AuthConsumer.user.id_role !== 4 &&
                    AuthConsumer.user.id_user !== publicacion.users.id_user ? (
                      <div className="d-grid gap-2">
                        {!denuncia ? (
                          denunciaPublicacion ? (
                            <button className="btn btn-warning btn-lg" disabled>
                              <i className="bi bi-exclamation-triangle px-2"></i>
                              Reportar
                            </button>
                          ) : (
                            <Link
                              className="btn btn-warning btn-lg"
                              to={`/publicaciones/${id}/denunciar`}
                            >
                              <i className="bi bi-exclamation-triangle px-2"></i>
                              Reportar
                            </Link>
                          )
                        ) : (
                          ""
                        )}
                        {!contraoferta ? (
                          denunciaPublicacion ? (
                            <button className="btn btn-danger btn-lg" disabled>
                              <i className="bi bi-arrow-left-right px-2"></i>
                              Contraofertar
                            </button>
                          ) : (
                            <Link
                              className="btn btn-danger btn-lg text-white"
                              to={`/publicaciones/${id}/contraofertar`}
                            >
                              <i className="bi bi-arrow-left-right px-2"></i>
                              Contraofertar
                            </Link>
                          )
                        ) : (
                          ""
                        )}
                        {user.id_user === 1 ? (
                          <>
                            {publicacion.state ? (
                              <>
                                <button
                                  className="btn btn-primary btn-lg"
                                  onClick={handleDisable}
                                >
                                  <i className="bi bi-x-lg"></i>
                                  Desactivar
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="btn btn-primary btn-lg"
                                  onClick={handleDisable}
                                >
                                  <i className="bi bi-x-lg"></i>
                                  Activar
                                </button>
                              </>
                            )}

                            <button
                              className="btn btn-danger btn-lg"
                              onClick={handleDelete}
                            >
                              <i className="bi bi-trash3"></i>
                              Eliminar
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PublicacionesDetalle;
