import CategoriasListaItem from "./CategoriasListaItem.js";

function CategoriasLista(props) {
  return (
    <ul className="list-unstyled row bg-white py-4">
      {props.items.map((item) => (
        <CategoriasListaItem
          key={item.id_category}
          item={item}
          auth={props.auth}
        />
      ))}
    </ul>
  );
}

export default CategoriasLista;
