const mystyle = {
  width: "100vw",
  height: "100vh",
  position: "fixed",
  top: 0,
  left: 0,
  // background: "rgba(0, 0, 0, 0.1)",
  background: "rgb(106, 106, 106, 66%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1050,
  flexDirection: "column",
};

function Loader() {
  return (
    <>
      <div style={mystyle}>
        <div className="lds-facebook">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <p className="text-white h6">Cargando...</p>
      </div>
    </>
  );
}

export default Loader;
