import React, { useEffect, useState } from "react";
import PerfilService from "../services/Perfil.js";
import PublicacionesService from "../services/Publicaciones";
import ContraofertasService from "../services/Contraofertas";
import { IMAGE_PATH } from "../constants/Constants.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader.js";
import { Tabs, Tab, Form, Button, Modal } from "react-bootstrap";
import GoBack from "../components/GoBack.js";
import UseAuthContext from "../hooks/UseAuthContext.js";
import AuthService from "../services/Auth.js";
import VendedoresService from "../services/Vendedores.js";
// import AuthService from "../services/Auth.js";
import Avatar from "react-avatar-edit";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const validationSchema = yup.object().shape({});

function Perfil(props) {
  const { AuthConsumer } = UseAuthContext();
  // console.log("AuthConsumer = ", AuthConsumer);
  // const user = AuthService.getUser();
  // console.log(user);
  const navigate = useNavigate();
  const [usuario, setUsuario] = useState([]);
  const [publicaciones, setPublicaciones] = useState([]);
  const [contraofertas, setContraofertas] = useState([]);
  const [formEnviado, setFormEnviado] = useState(false);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const handleCancel = () => {
    setShow(false);
  };

  const refreshPage = () => {
    navigate(0);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [showChangePhoto, setShowChangePhoto] = useState(false);
  const handleCancelPhoto = () => {
    setShowChangePhoto(false);
    setAvatar({ preview: null });
  };

  const handleClose = () => {
    AuthService.logout();
    setShow(false);
  };

  async function cargarPerfil() {
    // console.log("perfil id ", AuthConsumer.user.id_user);
    const data = await PerfilService.get(AuthConsumer.user.id_user);
    setUsuario(data);
    // console.log("perfil data ", usuario);
  }

  async function verificarEnvioFormulario() {
    // console.log("perfil id ", AuthConsumer.user.id_user);
    const data = await VendedoresService.getUser(AuthConsumer.user.id_user);
    console.log("data", data);
    if (data.length >= 1) {
      setFormEnviado(true);
    }

    console.log("setFormEnviado ", formEnviado);
  }

  async function cargarPublicaciones() {
    const Publicaciones = await PublicacionesService.all();
    setPublicaciones(
      Publicaciones.filter(
        (publicacion) => publicacion.id_user == usuario.id_user
      ).length
    );
  }

  async function cargarContraofertas() {
    const response = await ContraofertasService.getUser(
      AuthConsumer.user.id_user
    );
    // console.log("cargarContraofertas ", response);
    // setContraofertas(
    //   response.filter((data) => data.publications.id_user == usuario.id_user)
    // );
    setContraofertas(response.data.length);
    // console.log("contraofertas ", contraofertas);
    setLoading(false);
  }

  // useEffect(() => {
  //   refreshPage();
  // }, [loading]);

  useEffect(() => {
    // refreshPage();
    cargarPerfil();
    verificarEnvioFormulario();
  }, [loading]);

  useEffect(() => {
    cargarPublicaciones();
  }, [cargarPublicaciones]);

  useEffect(() => {
    cargarContraofertas();
    // console.log("perfil data ", usuario);
  }, [cargarContraofertas]);

  const handleLogout = () => {
    setShow(true);
  };

  const handleChangePhoto = () => {
    setShowChangePhoto(true);
  };

  const hiddenFileInput = React.useRef(null);
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    props.handleFile(fileUploaded);
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const src = null;
  const [avatar, setAvatar] = useState({
    preview: null,
    src: null,
  });
  // constructor() {
  //   const src = './example/einshtein.jpg'
  //   this.state = {
  //     preview: null,
  //     src
  //   }
  //   this.onCrop = this.onCrop.bind(this)
  //   this.onClose = this.onClose.bind(this)
  // }

  const onClose = () => {
    setAvatar({ preview: null });
  };

  const onCrop = (preview) => {
    setAvatar({ preview: preview });
  };

  function onSubmit(data) {
    // console.log("La data recibida por RHF es: ", avatar.preview);
    setLoading(true);
    // console.log("La data recibida por RHF es: ", data, fileData);
    PerfilService.avatar({
      avatar: avatar.preview,
      id_user: AuthConsumer.user.id_user,
    }).then((data) => {
      console.log("success?s ", data);
      if (data.success) {
        setShowChangePhoto(false);
        setLoading(false);
        window.location.reload(true);
      }
    });
  }

  // console.log("avatar.preview", avatar.preview);
  return (
    <>
      <Modal
        show={showChangePhoto}
        onHide={handleCancelPhoto}
        backdrop="static"
        keyboard={false}
        centered
        // className="modal-bottom border-bottom"
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title>Foto</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-avatar">
          {loading ? <Loader /> : ""}
          <Form action="#" onSubmit={handleSubmit(onSubmit)}>
            {/* <Button variant="light" size="lg" onClick={handleClick}>
            Selecciona la foto a cambiar
          </Button>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
          /> */}
            <div className="text-center py-2">
              {avatar.preview ? (
                <img src={avatar.preview} alt="Preview" className="img-fluid" />
              ) : (
                ""
              )}
            </div>
            <div className="img-fluid d-flex justify-content-center overflow-hidden">
              <Avatar
                width={190}
                height={190}
                onCrop={onCrop}
                onClose={onClose}
                cropRadius={100}
                src={src}
                mimeTypes={"image/jpeg,image/jpg,image/png"}
              />
            </div>
            {avatar.preview ? (
              <div className="d-grid gap-2 py-2">
                <Button variant="primary" type="submit" size="lg">
                  Publicar
                </Button>
              </div>
            ) : (
              ""
            )}
          </Form>
        </Modal.Body>

        <Modal.Footer className="d-block modal-border-top">
          {avatar.preview ? (
            ""
          ) : (
            <div className="d-grid gap-2">
              <Button variant="light" size="lg" onClick={handleCancelPhoto}>
                <i className="bi bi-x-lg"></i> Cancelar
              </Button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Cerrar sesión</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Estas seguro de cerrar sesión?</Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-6">
              <div className="d-grid gap-2">
                <Button
                  variant="ligth"
                  className="border-btn"
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
              </div>
            </div>
            <div className="col-6">
              <div className="d-grid gap-2">
                <Button variant="danger" onClick={handleClose}>
                  Salir
                </Button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="row bg-white ">
            <div className="container min-vh-view">
              <div className="row justify-content-center">
                <div className="col-10 pt-3 pb-5">
                  <div className="row">
                    <div className="col-12">
                      <div className="text-center position-relative d-flex justify-content-center">
                        <img
                          src={
                            usuario.avatar
                              ? IMAGE_PATH + "/usuarios/" + usuario.avatar
                              : "./imgs/empty.png"
                          }
                          className="rounded-circle img-fluid border w-50"
                          alt="perfil"
                        />
                        <button
                          className="btn btn-blue btn-sm position-absolute btn-1rem"
                          onClick={handleChangePhoto}
                        >
                          <i className="bi bi-camera fa-2x2"></i>
                        </button>
                      </div>
                    </div>
                    <div className="col-12 overflow-hidden pt-4 text-center">
                      <h4 className="mb-2">
                        {usuario.first_name + " " + usuario.last_name}
                      </h4>
                      <p className="text-description mb-4">{usuario.email}</p>
                      {/* <div className="mb-4 pb-2">
                      <Link to="https://facebook.com" className="px-2">
                        <i className="bi bi-facebook fa-2x text-white"></i>
                      </Link>
                      <Link to="https://twitter.com" className="px-2">
                        <i className="bi bi-twitter fa-2x text-white"></i>
                      </Link>
                      <Link to="https://instagram.com" className="px-2">
                        <i className="bi bi-instagram fa-2x text-white"></i>
                      </Link>
                      </div> */}
                    </div>
                  </div>

                  <ul className="list-unstyled row text-center justify-content-center">
                    <li className="col-5">
                      <div className="text-center py-2">
                        <p className="mb-2 h5">{publicaciones}</p>
                        <p className="text-description">Publicaciones</p>
                      </div>
                    </li>
                    <li className="col-5">
                      <div className="text-center py-2">
                        <p className="mb-2 h5">{contraofertas}</p>
                        <p className="text-description">Contraofertas</p>
                      </div>
                    </li>
                  </ul>
                  <ul className="list-unstyled mb-5">
                    <li className="col-12 py-3">
                      <Link
                        to={`/perfil/${usuario.id_user}/editar`}
                        className="row align-items-center"
                      >
                        <div className="col-2 m-auto text-description">
                          <i className="bi bi-person fa-2x"></i>
                        </div>
                        <div className="col-8">
                          <h3 className="h6">Editar perfil</h3>
                        </div>
                        <div className="col-2 m-auto d-flex justify-content-center text-gray">
                          <i className="bi bi-arrow-right-short fa-2x"></i>
                        </div>
                      </Link>
                    </li>
                    {usuario.id_user == 1 ? (
                      <>
                        <li className="col-12 py-3">
                          <Link
                            to={`/publicaciones`}
                            className="row align-items-center"
                          >
                            <div className="col-2 m-auto text-description">
                              <i className="bi bi-files fa-2x"></i>
                            </div>
                            <div className="col-8">
                              <h3 className="h6">Publicaciones</h3>
                            </div>
                            <div className="col-2 m-auto d-flex justify-content-center text-gray">
                              <i className="bi bi-arrow-right-short fa-2x"></i>
                            </div>
                          </Link>
                        </li>
                        <li className="col-12 py-3">
                          <Link
                            to={`/usuarios`}
                            className="row align-items-center"
                          >
                            <div className="col-2 m-auto text-description">
                              <i className="bi bi-people fa-2x"></i>
                            </div>
                            <div className="col-8">
                              <h3 className="h6">Usuarios</h3>
                            </div>
                            <div className="col-2 m-auto d-flex justify-content-center text-gray">
                              <i className="bi bi-arrow-right-short fa-2x"></i>
                            </div>
                          </Link>
                        </li>
                        <li className="col-12 py-3">
                          <Link
                            to={`/solicitudes`}
                            className="row align-items-center"
                          >
                            <div className="col-2 m-auto text-description">
                              <i className="bi bi-send fa-2x"></i>
                            </div>
                            <div className="col-8">
                              <h3 className="h6">Solicitudes</h3>
                            </div>
                            <div className="col-2 m-auto d-flex justify-content-center text-gray">
                              <i className="bi bi-arrow-right-short fa-2x"></i>
                            </div>
                          </Link>
                        </li>
                        <li className="col-12 py-3">
                          <Link
                            to={`/cobertura`}
                            className="row align-items-center"
                          >
                            <div className="col-2 m-auto text-description">
                              <i className="bi bi-pin-map fa-2x"></i>
                            </div>
                            <div className="col-8">
                              <h3 className="h6">Cobertura</h3>
                            </div>
                            <div className="col-2 m-auto d-flex justify-content-center text-gray">
                              <i className="bi bi-arrow-right-short fa-2x"></i>
                            </div>
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="col-12 py-3">
                          <Link
                            to={`/publicaciones/usuario/${AuthConsumer.user.id_user}`}
                            className="row align-items-center"
                          >
                            <div className="col-2 m-auto text-description">
                              <i className="bi bi-files fa-2x"></i>
                            </div>
                            <div className="col-8">
                              <h3 className="h6">Mis publicaciones</h3>
                            </div>
                            <div className="col-2 m-auto d-flex justify-content-center text-gray">
                              <i className="bi bi-arrow-right-short fa-2x"></i>
                            </div>
                          </Link>
                        </li>

                        {AuthConsumer.user.id_role !== 3 ? (
                          formEnviado ? (
                            ""
                          ) : (
                            <li className="col-12 py-3">
                              <Link
                                to={`/formVendedor`}
                                className="row align-items-center"
                              >
                                <div className="col-2 m-auto text-description">
                                  <i className="bi bi-people-fill fa-2x"></i>
                                </div>
                                <div className="col-8">
                                  <h3 className="h6">Formulario vendedor</h3>
                                </div>
                                <div className="col-2 m-auto d-flex justify-content-center text-gray">
                                  <i className="bi bi-arrow-right-short fa-2x"></i>
                                </div>
                              </Link>
                            </li>
                          )
                        ) : (
                          <li className="col-12 py-3">
                            <Link
                              to={`/contraofertas/usuario/${AuthConsumer.user.id_user}`}
                              className="row align-items-center"
                            >
                              <div className="col-2 m-auto text-description">
                                <i className="bi bi-arrow-left-right fa-2x2"></i>
                              </div>
                              <div className="col-8">
                                <h3 className="h6">Mis contraofertas</h3>
                              </div>
                              <div className="col-2 m-auto d-flex justify-content-center text-gray">
                                <i className="bi bi-arrow-right-short fa-2x"></i>
                              </div>
                            </Link>
                          </li>
                        )}
                      </>
                    )}

                    <li className="col-12 py-3">
                      <Link
                        to="#"
                        onClick={handleLogout}
                        className="row align-items-center"
                      >
                        <div className="col-2 m-auto text-description">
                          <i className="bi bi-power fa-2x"></i>
                        </div>
                        <div className="col-8">
                          <h3 className="h6">Salir</h3>
                        </div>
                        <div className="col-2 m-auto d-flex justify-content-center text-gray">
                          <i className="bi bi-arrow-right-short fa-2x"></i>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Perfil;
