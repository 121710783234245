import { API_HOST } from "../constants/Constants.js";

const PerfilService = {
  async get(id) {
    const response = await fetch(`${API_HOST}/users/${id}`);
    const jsonData = await response.json();
    return jsonData;
  },
  async edit(data) {
    const response = await fetch(`${API_HOST}/users/edit`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    });
    const parsed = await response.json();
    return parsed;
  },
  async avatar(data) {
    const response = await fetch(`${API_HOST}/users/avatar`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    });
    const parsed = await response.json();
    return parsed;
  },
  async changePassword(data) {
    const response = await fetch(`${API_HOST}/users/${data.id_user}/password`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    });
    const parsed = await response.json();
    return parsed;
  },
};

export default PerfilService;
