import { useEffect, useState } from "react";
import AuthService from "../services/Auth.js";

/**
 * Los datos del usuario autenticado.
 * Si no hay, sus valores son null.
 *
 * @return {{user: {id_usuario: null, email: null}}}
 */
function UseAuth() {
  const user = AuthService.getUser();
  const [auth, setAuth] = useState({
    user,
    logged: user.email !== null,
  });

  useEffect(() => {
    const unsubscribe = AuthService.subscribe((user) => {
      if (user.email !== auth.user.email) {
        setAuth({
          user: { ...user },
          logged: user.email !== null,
        });
      }
    });
    return unsubscribe;
  }, [auth]);

  return auth;
}

export default UseAuth;
