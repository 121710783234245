import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import GoBack from "../components/GoBack";
import Loader from "../components/Loader";
import { IMAGE_PATH } from "../constants/Constants";
import UseAuthContext from "../hooks/UseAuthContext";
import AuthService from "../services/Auth";
import VendedoresService from "../services/Vendedores";

function SolicitudesDetalle() {
  const { AuthConsumer } = UseAuthContext();
  console.log("AuthConsumer = ", AuthConsumer);
  const navigate = useNavigate();
  const [solicitud, setSolicitud] = useState();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [showRol, setShowRol] = useState(false);
  const handleCloseDisable = () => {
    setShowRol(false);
    navigate(-3);
  };

  const handleVendor = async () => {
    const success = await VendedoresService.rol(id);
    console.log("Éxito al desactivar: ", success);
    // window.location.reload(true);
    setShowRol(true);
  };

  useEffect(() => {
    if (loading) {
      (async () => {
        const response = await VendedoresService.get(id);
        console.log("VendedoresService.get(id) =", response.data);
        setSolicitud(response.data);
        // console.log("solicitud ", solicitud);
        setLoading(false);
      })();
    }
  }, [loading]);

  return (
    <>
      <Modal show={showRol} onHide={handleCloseDisable} centered>
        <Modal.Header>
          <Modal.Title>Solicitud vendedor</Modal.Title>
        </Modal.Header>
        <Modal.Body>Se habilitó correctamente el rol vendedor</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleCloseDisable}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
      {loading ? (
        <Loader />
      ) : (
        <div className="row bg-white ">
          <div className="container min-vh-view">
            <div className="row justify-content-center">
              <div className="col-10 arrow">
                <GoBack />
              </div>

              <div className="col-10 mb-5">
                <div className="text-center">
                  <img
                    src={
                      solicitud.users.avatar
                        ? IMAGE_PATH + "/usuarios/" + solicitud.users.avatar
                        : "../imgs/empty.png"
                    }
                    className="rounded-circle img-fluid border w-50"
                    alt="perfil"
                  />
                </div>

                <h2 className="h4">
                  {solicitud.first_name} {solicitud.last_name}
                </h2>

                <div className="py-3 text-description">
                  {/* {solicitud.description} */}
                </div>
                <dl className="row">
                  <dt className="col-3">Usuario</dt>
                  <dd className="col-9">{solicitud.users.username}</dd>
                  <dt className="col-3">Actividad</dt>
                  <dd className="col-9">{solicitud.activity}</dd>
                  <dt className="col-3">Email</dt>
                  <dd className="col-9">{solicitud.email}</dd>
                  <dt className="col-3">Telefono</dt>
                  <dd className="col-9">{solicitud.phone}</dd>
                  <dt className="col-3">Dirección</dt>
                  <dd className="col-9">{solicitud.address}</dd>
                  <dt className="col-3">Piso</dt>
                  <dd className="col-9">{solicitud.floor}</dd>
                  <dt className="col-3">Codigo postal</dt>
                  <dd className="col-9">{solicitud.postal_code}</dd>
                  <dt className="col-3">Provincia</dt>
                  <dd className="col-9">{solicitud.province}</dd>
                </dl>
                <div className="d-grid gap-2">
                  <button
                    className="btn btn-success btn-lg"
                    onClick={handleVendor}
                  >
                    <i className="bi bi-person-check px-2"></i>
                    Habilitar vendedor
                  </button>
                  <Link
                    to={`/solicitudes/${id}/denegar`}
                    className="btn btn-danger btn-lg"
                  >
                    <i className="bi bi-person-x px-2"></i>
                    Denegar solicitud
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SolicitudesDetalle;
