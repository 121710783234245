import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import GoBack from "../components/GoBack";
import Loader from "../components/Loader";
import { IMAGE_PATH } from "../constants/Constants";
import ContraofertasService from "../services/Contraofertas";
import moment from "moment";
import "moment/locale/es";
import PublicacionesService from "../services/Publicaciones";
import { Button, Modal } from "react-bootstrap";
import UseAuthContext from "../hooks/UseAuthContext";
import NotificacionesService from "../services/Notificaciones";

function Notificaciones() {
  const { AuthConsumer } = UseAuthContext();
  const [loading, setLoading] = useState(true);
  const [notificaciones, setNotificaciones] = useState([]);
  const [counter, setCounter] = useState(0);
  const [empty, setEmpty] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setCounter(counter + 1);
    setShow(false);
  };

  // async function contraofertasAll() {
  //   const response = await ContraofertasService.getUser(
  //     AuthConsumer.user.id_user
  //     // 5
  //   );
  //   console.log("contraofertasAll", response);
  //   if (response.success) {
  //     setContraofertas(response.data);
  //   } else {
  //     setContraofertas("");
  //   }
  //   setLoading(false);
  // }

  // useEffect(() => {
  //   contraofertasAll();
  //   console.log("ContraofertasService.get(id) =", contraofertas);
  // }, [counter]);

  async function notificacionesAll() {
    const response = await NotificacionesService.getUser(
      AuthConsumer.user.id_user
    );
    console.log("NotificacionesService", response);
    if (response.data.length >= 1) {
      setNotificaciones(response.data);
    } else {
      setNotificaciones("");
    }
    setLoading(false);
  }

  useEffect(() => {
    notificacionesAll();
    // console.log("notificacionesAll =", contraofertas);
  }, []);

  async function deleteCounteroffer(id) {
    console.log("id eliminar = ", id);
    const success = await ContraofertasService.delete(id);
    console.log("Éxito al eliminar: ", success);
    if (success) {
      setCounter(counter + 1);
    }
  }

  async function agreeCounteroffer(id) {
    const success = await ContraofertasService.edit(id);
    console.log("agreeCounteroffer: ", success);
    if (success) {
      setShow(true);
    }
    // window.location.reload(true);
    // props.update();
    // setShow(false);
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="row bg-white ">
            <div className="container min-vh-view">
              <div className="row justify-content-center">
                <div className="col-10 arrow">
                  <GoBack />
                </div>
                <div className="col-10">
                  <h3>Notificaciones</h3>
                  {notificaciones ? (
                    <>
                      <ul className="list-unstyled">
                        {notificaciones.map((item) => (
                          <li key={item.id_notification} className="py-3">
                            <Link to={`/contraofertas`} className="row">
                              <div className="col-3 m-auto">
                                <img
                                  src={
                                    item.users.avatar
                                      ? IMAGE_PATH +
                                        "/usuarios/" +
                                        item.users.avatar
                                      : IMAGE_PATH + "/empty.png"
                                  }
                                  className="img-fluid rounded-circle"
                                  alt={item.users.avatar}
                                />
                              </div>
                              <div className="col-9">
                                <p className="d-flex justify-content-end small mb-0">
                                  {/* {moment(item.created_at).format("LT")} */}
                                  {/* {moment(item.created_at).format("LLL")} */}
                                  {moment(item.created_at).format(
                                    "D MMMM, h:mm a"
                                  )}
                                  {/* <Moment fromNow>{item.created_at}</Moment> */}
                                </p>

                                <h5>{item.users.username}</h5>
                                <p className="small m-0">{item.subject}</p>
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <>
                      <div className="py-4 px-2">
                        <div className="text-center py-2">
                          <i className="bi bi-bell fa-4x"></i>
                        </div>
                        <h3 className="card-title h6 fw-normal text-center">
                          No hay actualmente ninguna notificación
                        </h3>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Notificaciones;
