import GoBack from "../components/GoBack";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../services/Auth";
import PerfilService from "../services/Perfil";
import UseAuthContext from "../hooks/UseAuthContext";
import Loader from "../components/Loader";

const validationSchema = yup.object().shape({
  // username: yup
  //   .string()
  //   .transform((currentValue) => currentValue.split(" ").join(""))
  //   .min(2, "Campo minimo de 2 caracteres")
  //   .max(20, "Campo máximo de 20 caracteres")
  //   .required("Campo obligatorio"),
  password: yup
    .string()
    .required("Campo obligatorio")
    .min(5, "Campo minimo de 5 caracteres"),
  passwordNew: yup
    .string()
    .required("Campo obligatorio")
    .min(5, "Campo minimo de 5 caracteres"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("passwordNew"), null], "Las contraseñas deben coincidir"),
});

function PerfilClave() {
  const { AuthConsumer } = UseAuthContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [errorChangePassword, setErrorChangePassword] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    navigate(-1);
    setShow(false);
  };

  const registerWithMysql = async (data) => {
    const response = await PerfilService.changePassword({
      data,
      id_user: AuthConsumer.user.id_user,
    });
    console.log("PerfilService.changePassword ", response);
    if (response.success) {
      setShow(true);
    } else {
      setErrorChangePassword(response.data);
    }

    // setErrorLogin(true);
    setLoading(false);
  };

  const onSubmit = async (data) => {
    console.log("Data: ", data);
    setLoading(true);
    // registerWithFirebase();
    registerWithMysql(data);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Cambio de contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          La contraseña se ha cambiado correctamente. Deberá utilizarla la
          próxima vez que inicie sesión en la aplicación
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
      {loading ? (
        <Loader />
      ) : (
        <div className="row bg-white ">
          <div className="container min-vh-view">
            <div className="row justify-content-center">
              <div className="col-10 arrow">
                <GoBack />
              </div>
              <div className="col-10">
                <h2>Editando contraseña</h2>
                <p className="text-description pb-4">
                  Puedes cambiar tu contraseña por razones de seguridad o
                  restablecerla si la olvidas
                </p>
                {errorChangePassword && (
                  <div className="alert alert-danger row" role="alert">
                    <div className="col-10">
                      <i className="bi bi-x-octagon fa-2x pe-2"></i>{" "}
                      {errorChangePassword}
                    </div>
                  </div>
                )}
                <Form
                  action="#"
                  method="post"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <Form.Group className="mb-3" controlId="password">
                    <FloatingLabel
                      controlId="floatingInputEmail"
                      label="Contraseña actual"
                      className="mb-3"
                    >
                      <Form.Control
                        type="password"
                        placeholder="Escribe tu contraseña"
                        name="password"
                        {...register("password")}
                      />
                      {errors.password && (
                        <div className="alert alert-danger" role="alert">
                          {errors.password.message}
                        </div>
                      )}
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="passwordNew">
                    <FloatingLabel
                      controlId="floatingInputPassword"
                      label="Contraseña nueva"
                      className="mb-3"
                    >
                      <Form.Control
                        type="password"
                        placeholder="Ingresa una contraseña nueva"
                        name="passwordNew"
                        {...register("passwordNew")}
                      />
                      {errors.passwordNew && (
                        <div className="alert alert-danger" role="alert">
                          {errors.passwordNew.message}
                        </div>
                      )}
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="password_confirmation"
                  >
                    <FloatingLabel
                      controlId="floatingInputCPassword"
                      label="Confirmar contraseña"
                      className="mb-3"
                    >
                      <Form.Control
                        type="password"
                        placeholder="Confirma la contraseña"
                        name="password_confirmation"
                        {...register("password_confirmation")}
                      />
                      {errors.password_confirmation && (
                        <div className="alert alert-danger" role="alert">
                          {errors.password_confirmation.message}
                        </div>
                      )}
                    </FloatingLabel>
                  </Form.Group>

                  <div className="d-grid gap-2 py-2">
                    <Button variant="danger" size="lg" type="submit">
                      Registrarse
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PerfilClave;
