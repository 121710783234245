import { API_HOST } from "../constants/Constants.js";

const ContraofertasService = {
  all() {
    return fetch(`${API_HOST}/counteroffers`)
      .then((response) => response.json())
      .then((parsed) => parsed.data);
  },
  async get(id) {
    const response = await fetch(`${API_HOST}/counteroffers/${id}`);
    const jsonData = await response.json();
    return jsonData;
  },
  async getUser(id) {
    const response = await fetch(`${API_HOST}/counteroffers/user/${id}`);
    const jsonData = await response.json();
    return jsonData;
  },

  async getUserSender(id) {
    const response = await fetch(`${API_HOST}/counteroffers/userSender/${id}`);
    const jsonData = await response.json();
    return jsonData;
  },
  async getPublicacion(id) {
    const response = await fetch(`${API_HOST}/counteroffers/publication/${id}`);
    const jsonData = await response.json();
    return jsonData;
  },
  async getContraofertas(id) {
    const response = await fetch(`${API_HOST}/counteroffers/offers/${id}`);
    const jsonData = await response.json();
    return jsonData;
  },
  async getCategory(id) {
    const response = await fetch(`${API_HOST}/counteroffers/category/${id}`);
    const jsonData = await response.json();
    return jsonData;
  },
  async create(data) {
    const response = await fetch(`${API_HOST}/counteroffers`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    });
    const parsed = await response.json();
    return parsed;
  },
  async edit(id) {
    const response = await fetch(`${API_HOST}/counteroffers/${id}/edit`, {
      method: "POST",
      body: JSON.stringify({ id_counteroffer: id }),
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    });
    const parsed = await response.json();
    return parsed;
  },
  async delete(id) {
    const response = await fetch(`${API_HOST}/counteroffers/${id}`, {
      method: "DELETE",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    });
    const parsed = await response.json();
    // empresas = empresas.filter(empresa => empresa.id_empresa !== id);
    return parsed.success;
  },
};

export default ContraofertasService;
