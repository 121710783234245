import React, { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import UseAuthContext from "../hooks/UseAuthContext.js";
import Loader from "./Loader.js";

function RouteAuth({ children }) {
  const [loading, setLoading] = useState(true);
  //   console.log("routeAuth props =", children);
  const { AuthConsumer } = UseAuthContext();
  //   console.log("AuthConsumer routeAuht =", AuthConsumer);
  let navigate = useNavigate();

  useEffect(() => {
    // console.log("AuthConsumer.logged", AuthConsumer.logged);
    if (AuthConsumer.logged == false) {
      navigate("/");
    }
    setLoading(false);
  });

  //   return <Route {...props} />;
  return <>{loading ? <Loader /> : children}</>;
}

export default RouteAuth;
