import { useEffect, useState } from "react";
import GoBack from "../components/GoBack";
import Loader from "../components/Loader";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import PerfilService from "../services/Perfil";
import AuthService from "../services/Auth";
import UsuariosService from "../services/Usuarios";
import { useLoadScript, Marker, GoogleMap } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { getValue } from "@testing-library/user-event/dist/utils";
import UseAuthContext from "../hooks/UseAuthContext";
import moment from "moment";
import "moment/locale/es";

const validationSchema = yup.object().shape({
  username: yup
    .string()
    .transform((currentValue) => currentValue.split(" ").join(""))
    .min(2, "Campo minimo de 2 caracteres")
    .max(20, "Campo máximo de 20 caracteres")
    .required("Campo obligatorio"),
  first_name: yup
    .string()
    .matches(/^[aA-zZ\s]+$/, "Solo se permiten letras para este campo ")
    .trim("El campo no puede incluir espacios iniciales y finales")
    .required("Campo obligatorio")
    .min(2, "Campo minimo de 2 letras")
    .max(25, "Campo máximo de 25 letras"),
  last_name: yup
    .string()
    .matches(/^[aA-zZ\s]+$/, "Solo se permiten letras para este campo ")
    .trim("El campo no puede incluir espacios iniciales y finales")
    .required("Campo obligatorio")
    .min(2, "Campo minimo de 2 letras")
    .max(25, "Campo máximo de 25 letras"),
  email: yup
    .string()
    .email("Formato de mail invalido")
    .required("Campo obligatorio"),
  date_of_birth: yup.string().required("Campo"),
  address: yup.string().nullable(),
  lat: yup.string(),
  long: yup.string(),
});

const PerfilEditar = () => {
  const [libraries] = useState(["places"]);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBYhhgSDB2juZHFO9udgoIL8lN_SIT90Dw",
    libraries,
  });
  if (!isLoaded) return <Loader />;
  return <Formulario />;
};

function Formulario() {
  const { AuthConsumer } = UseAuthContext();
  const [perfil, setPerfil] = useState([]);

  const [coordinates, setCoordinates] = useState({
    lat: -34.693722,
    lng: -58.381592,
  });

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    // console.log("evaluar", value == getValue("address"));
    setSelected(value);
    setUsuario({ address: value });
    setValue("address", value);
    setValue("lat", latLng.lat);
    setValue("long", latLng.lng);
    setCoordinates(latLng);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [errorPerfil, setErrorPerfil] = useState({
    username: null,
    email: null,
  });
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  // const [usuario, setUsuario] = useState();

  const [usuario, setUsuario] = useState({
    username: null,
    first_name: null,
    last_name: null,
    email: null,
    date_of_birth: null,
    address: null,
  });
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    navigate(-1);
    setShow(false);
  };
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => {
    setShowDelete(false);
    navigate(-1);
  };

  const [showDisable, setShowDisable] = useState(false);
  const handleCloseDisable = () => {
    setShowDisable(false);
    navigate(-1);
  };

  // async function cargarPerfil() {
  //   console.log("perfil id ", id);
  //   const data = await PerfilService.get(AuthService.getUser().id_user);
  //   setUsuario(data);
  //   console.log("perfil data ", usuario);
  // }

  const onSubmit = async (data) => {
    setSelected(selected);
    data.username =
      data.username.charAt(0).toUpperCase() + data.username.slice(1);
    data.address = selected;
    data.first_name =
      data.first_name.replace(/\s+/g, "").charAt(0).toUpperCase() +
      data.first_name.slice(1);
    data.last_name =
      data.last_name.replace(/\s+/g, "").charAt(0).toUpperCase() +
      data.last_name.slice(1);
    console.log("Data: ", data);

    setErrorPerfil("");
    // console.log("verificar ", data.address);
    setLoading(true);
    // registerWithFirebase();
    PerfilService.edit({
      ...data,
    }).then((data) => {
      // console.log("success?s ", data);
      if (data.success) {
        setLoading(false);
        setShow(true);
      } else {
        setLoading(false);

        setErrorPerfil(data.errors);
      }
    });
  };
  // console.log("errorPerfil data", errorPerfil);
  const handleDelete = async () => {
    const success = await UsuariosService.delete(id);
    console.log("Éxito al eliminar usuario: ", success);
    // window.location.reload(true);
    setShowDelete(true);
  };

  const handleDisable = async () => {
    const success = await UsuariosService.state(id);
    console.log("Éxito al desactivar: ", success);
    // window.location.reload(true);
    setShowDisable(true);
  };
  const [selected, setSelected] = useState("");
  async function editProfile() {
    // console.log("id profile", id);
    const dataUsuario = await PerfilService.get(id);
    setValue("id_user", dataUsuario.id_user);
    // setValue("username", dataUsuario.username);
    // setValue("first_name", dataUsuario.first_name);
    // setValue("last_name", dataUsuario.last_name);
    // setValue("email", dataUsuario.email);
    // setValue(dataUsuario.date_of_birth);
    // setValue("address", dataUsuario.address);
    setSelected(dataUsuario.address);
    setPerfil(dataUsuario);
    setUsuario(dataUsuario);
    console.log("data usuario Profile ", dataUsuario);
    setLoading(false);
  }

  // console.log("usuario =", usuario.date_of_birth);

  useEffect(() => {
    editProfile();
  }, [loading]);

  return (
    <>
      <Modal show={showDelete} onHide={handleCloseDelete} centered>
        <Modal.Header>
          <Modal.Title>Usuarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>Se elimino correctamente el usuario</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleCloseDelete}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDisable} onHide={handleCloseDisable} centered>
        <Modal.Header>
          <Modal.Title>Usuarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>Se cambio correctamente el estado del usuario</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleCloseDisable}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            className="sticky"
          >
            <Modal.Header>
              <Modal.Title>Perfil actualizado</Modal.Title>
            </Modal.Header>
            <Modal.Body>Se actualizo correctamente el perfil</Modal.Body>
            <Modal.Footer>
              <Button variant="success btn-lg" onClick={handleClose}>
                Continuar
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="row bg-white ">
            <div className="container min-vh-view">
              <div className="row justify-content-center">
                <div className="col-10 arrow">
                  <GoBack />
                </div>
                <div className="col-10">
                  <h2>Editando perfil</h2>
                  <p className="text-description pb-4">
                    Completa todo el formulario para mantener actualizado tu
                    perfil en la aplicación
                  </p>
                  {/* <h2 className="text-center h4">Iniciar sesión</h2> */}

                  {/* {errorPerfil.map((item) => (
                    <div className="alert alert-danger" role="alert"></div>
                  ))} */}
                  <Form
                    action="#"
                    method="post"
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    className="mb-5"
                  >
                    <Form.Group className="mb-3" controlId="username">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Usuario"
                        className="mb-3"
                      >
                        <Form.Control
                          type="username"
                          placeholder="Escribe un nombre de usuario"
                          name="username"
                          {...register("username", { value: usuario.username })}
                        />
                      </FloatingLabel>

                      {errors.username && (
                        <div className="alert alert-danger" role="alert">
                          {errors.username.message}
                        </div>
                      )}
                      {errorPerfil.username ? (
                        <div className="alert alert-danger" role="alert">
                          <p className="m-0">{errorPerfil.username}</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <Form.Group controlId="id_user">
                      <Form.Control type="hidden" name="id_user"></Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="first_name">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Nombre"
                        className="mb-3"
                      >
                        <Form.Control
                          type="first_name"
                          placeholder="Escribe tu nombre"
                          name="first_name"
                          {...register("first_name", {
                            value: usuario.first_name,
                          })}
                        />
                      </FloatingLabel>

                      {errors.first_name && (
                        <div className="alert alert-danger" role="alert">
                          {errors.first_name.message}
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="last_name">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Apellido"
                        className="mb-3"
                      >
                        <Form.Control
                          type="last_name"
                          placeholder="Escribe tu apellido"
                          name="last_name"
                          {...register("last_name", {
                            value: usuario.last_name,
                          })}
                        />
                      </FloatingLabel>

                      {errors.last_name && (
                        <div className="alert alert-danger" role="alert">
                          {errors.last_name.message}
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="email">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Email"
                        className="mb-3"
                      >
                        <Form.Control
                          type="email"
                          placeholder="Escribe tu mail"
                          name="email"
                          {...register("email", { value: usuario.email })}
                        />
                      </FloatingLabel>

                      {errors.email && (
                        <div className="alert alert-danger" role="alert">
                          {errors.email.message}
                        </div>
                      )}
                      {errorPerfil.email && (
                        <div className="alert alert-danger" role="alert">
                          <p className="m-0">{errorPerfil.email}</p>
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="date_of_birth">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Fecha de nacimiento"
                        className="mb-3 notRequired"
                      >
                        <Form.Control
                          type="date"
                          placeholder="Ingresa tu fecha de nacimiento"
                          name="date_of_birth"
                          {...register("date_of_birth", {
                            value: usuario.date_of_birth,
                          })}
                        />
                      </FloatingLabel>

                      {errors.date_of_birth && (
                        <div className="alert alert-danger" role="alert">
                          {errors.date_of_birth.message}
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="address">
                      <PlacesAutocomplete
                        value={selected}
                        onChange={setSelected}
                        onSelect={handleSelect}
                        // selectProps={{ ...register("address") }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="">
                            <div className="">
                              <FloatingLabel
                                controlId="address"
                                label="Dirección"
                                className="mb-3 notRequired"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Ingresa tu domicilio"
                                  name="address"
                                  {...register("address")}
                                  className=""
                                  {...getInputProps({
                                    placeholder: "Ingrese su dirección",
                                  })}
                                />
                              </FloatingLabel>

                              <div className="suggestions">
                                {loading ? <p>Cargando...</p> : null}
                                {suggestions.map((suggestion) => {
                                  // console.log(suggestion);
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        },
                                  };
                                  return (
                                    <select
                                      key={suggestion.placeId}
                                      {...getSuggestionItemProps(
                                        suggestion,
                                        {}
                                      )}
                                      className={style}
                                    >
                                      <option>{suggestion.description}</option>
                                    </select>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>

                      {errors.address && (
                        <div className="alert alert-danger" role="alert">
                          {errors.address.message}
                        </div>
                      )}
                    </Form.Group>

                    <div className="d-flex justify-content-end">
                      <p className="requiredBefore small">
                        Campos obligatorios
                      </p>
                    </div>

                    <div className="d-grid gap-2 py-3">
                      <Link
                        to={`/perfil/${perfil.id_user}/clave`}
                        // onClick={handleLogout}
                        className="row align-items-center"
                      >
                        <div className="col-2 m-auto text-description">
                          <i className="bi bi-lock fa-2x"></i>
                        </div>
                        <div className="col-8">
                          <h3 className="h6">Cambiar contraseña</h3>
                        </div>
                        <div className="col-2 m-auto d-flex justify-content-center text-gray">
                          <i className="bi bi-arrow-right-short fa-2x"></i>
                        </div>
                      </Link>
                    </div>

                    <div className="d-grid gap-2 py-2">
                      <Button variant="success" size="lg" type="submit">
                        Actualizar
                      </Button>
                      {AuthConsumer.user.id_user === 1 ? (
                        <>
                          {perfil.state ? (
                            <>
                              <Button
                                variant="warning"
                                size="lg"
                                onClick={handleDisable}
                              >
                                Desactivar
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                variant="warning"
                                size="lg"
                                onClick={handleDisable}
                              >
                                Activar
                              </Button>
                            </>
                          )}
                          <Button
                            variant="danger"
                            size="lg"
                            onClick={handleDelete}
                          >
                            Eliminar
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PerfilEditar;
