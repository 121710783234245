import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import PublicacionesService from "../services/Publicaciones";
import PublicacionesLista from "../components/PublicacionesLista.js";
import GoBack from "../components/GoBack";
import Loader from "../components/Loader";

function Publicaciones() {
  const [publicaciones, setPublicaciones] = useState([]);
  const [loading, setLoading] = useState(true);

  async function cargarPublicaciones() {
    const data = await PublicacionesService.all();
    // console.log("cargarPublicaciones: ", data);
    setPublicaciones(data);
    setLoading(false);
  }

  useEffect(() => {
    cargarPublicaciones();
  }, []);

  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="row bg-white ">
        <div className="container min-vh-view">
          <div className="row justify-content-center">
            <div className="col-10 arrow">
              <GoBack />
            </div>

            <div className="col-10 mb-5">
              <h2>Publicaciones</h2>
              <p>
                Listado de todas las publicaciones generados por los usuarios
              </p>
              <PublicacionesLista items={publicaciones} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Publicaciones;
