import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import GoBack from "../components/GoBack";
import Loader from "../components/Loader";
import PublicacionesLista from "../components/PublicacionesLista";
import PublicacionesService from "../services/Publicaciones";

function PublicacionesUsuario(props) {
  console.log("publicaciones usuario =");
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [usuario, setUsuario] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [counter, setCounter] = useState(0);
  console.log("counter =", counter);
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => {
    setShowDelete(true);
  };

  const refresh = () => {
    setCounter(counter + 1);
    handleShowDelete();
  };

  useEffect(() => {
    const PublicacionesUsuario = async () => {
      const dataUsuario = await PublicacionesService.getUser(id);
      setUsuario(dataUsuario);
      if (dataUsuario.length === 0) {
        setEmpty(true);
      }
      // console.log("PerfilService.getUsuario(id) = ", dataUsuario);
      setLoading(false);
    };
    PublicacionesUsuario();
  }, [counter]);
  return (
    <>
      <Modal show={showDelete} onHide={handleCloseDelete} centered>
        <Modal.Header>
          <Modal.Title>Publicación</Modal.Title>
        </Modal.Header>
        <Modal.Body>Se elimino correctamente la publicación</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleCloseDelete}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
      {loading ? (
        <Loader />
      ) : (
        <div className="row bg-white ">
          <div className="container min-vh-view">
            <div className="row justify-content-center">
              <div className="col-10 arrow">
                <GoBack />
              </div>
              <div className="col-10">
                {empty ? (
                  <>
                    <div className="py-3">
                      <div className="text-center py-2">
                        <i className="bi bi-files fa-4x"></i>
                      </div>
                      <h3 className="card-title h6 fw-normal">
                        No hay actualmente ninguna publicación
                      </h3>
                    </div>
                  </>
                ) : (
                  <>
                    <h2 className="mb-3">Mis publicaciones</h2>
                    <PublicacionesLista
                      items={usuario}
                      auth={props.auth}
                      update={refresh}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PublicacionesUsuario;
