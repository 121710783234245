// import React, {useState, useEffect} from 'react';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import GoBack from "../components/GoBack";
import PublicacionesService from "../services/Publicaciones";
import CategoriasService from "../services/Categorias";
import { useEffect, useRef, useState } from "react";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import Loader from "../components/Loader";
import UseAuthContext from "../hooks/UseAuthContext";

const validationSchema = yup.object().shape({
  product: yup
    .string()
    .required("Campo obligatorio")
    .min(2, "campo minimo de 2 caracteres"),
  price: yup
    .number()
    .typeError("Campo obligatorio")
    .required("Campo obligatorio")
    .min(1, "Valor minimo 1 numero")
    .max(999999, "Valor maximo 999.999"),
  id_category: yup
    .number()
    .typeError("Campo obligatorio")
    .required("Campo obligatorio")
    .moreThan(0, "Selecciona una opción"),
  description: yup
    .string()
    .required("Campo obligatorio")
    .min(2, "Campo minimo de 2 caracteres"),
});

function PublicacionesNueva() {
  // console.log("publicaciones nueva = ", props);
  const { AuthConsumer } = UseAuthContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const fileRef = useRef(null);
  const [fileData, setFileData] = useState();

  const [show, setShow] = useState(false);
  const handleClose = () => {
    navigate(-1);
    setShow(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  function handleChangeImage(ev) {
    const reader = new FileReader();

    reader.addEventListener("load", function () {
      setFileData(reader.result);
      // console.log("fileData = ", fileData);
    });
    reader.readAsDataURL(fileRef.current.files[0]);
  }

  function onSubmit(data) {
    setLoading(true);
    // console.log("La data recibida por RHF es: ", data, fileData);
    PublicacionesService.create({
      ...data,
      image: fileData,
      id_user: AuthConsumer.user.id_user,
    }).then((data) => {
      console.log("success?s ", data);
      if (data.success) {
        setLoading(false);
        setShow(true);
      }
    });
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      const Categorias = await CategoriasService.all();
      // console.log("CategoriasService.all() =", Categorias.data);
      setCategorias(Categorias.data);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Publicacion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Se ha generado correctamente la publicación</Modal.Body>
        <Modal.Footer>
          <Button variant="success btn-lg" onClick={handleClose}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="row bg-white ">
            <div className="container min-vh-view">
              <div className="row justify-content-center">
                <div className="col-10 arrow">
                  <GoBack />
                </div>

                <div className="col-10 mb-5">
                  <h2>Crear publicación</h2>
                  <p className="text-description">
                    Completa el formulario para generar la publicación. Ingresa
                    la mayor cantidad de información para que tengas más
                    oportunidades de encontrar una contraoferta
                  </p>
                  <Form
                    action="#"
                    onSubmit={handleSubmit(onSubmit)}
                    className="pb-4"
                  >
                    <Form.Group className="mb-3" controlId="product">
                      <FloatingLabel
                        controlId="product"
                        label="Producto"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="product"
                          placeholder="Nombre del producto buscado"
                          {...register("product")}
                        />
                        {errors.product && (
                          <div className="alert alert-danger" role="alert">
                            {errors.product.message}
                          </div>
                        )}
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="price">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Precio"
                        className="mb-3"
                      >
                        <Form.Control
                          type="number"
                          placeholder="Precio a pagar"
                          name="price"
                          {...register("price")}
                        />
                        {errors.price && (
                          <div className="alert alert-danger" role="alert">
                            {errors.price.message}
                          </div>
                        )}
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="id_category">
                      <FloatingLabel
                        controlId="floatingSelect"
                        label="Categoria"
                      >
                        <Form.Select
                          name="id_category"
                          className="form-control"
                          {...register("id_category")}
                        >
                          <option value="0">Seleccione una opción</option>
                          {categorias.map((item) => (
                            <option
                              value={item.id_category}
                              key={item.id_category}
                            >
                              {item.name}
                            </option>
                          ))}
                        </Form.Select>
                        {errors.id_category && (
                          <div className="alert alert-danger" role="alert">
                            {errors.id_category.message}
                          </div>
                        )}
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="description">
                      <FloatingLabel
                        controlId="floatingTextarea"
                        label="Descripción"
                        className="mb-3"
                      >
                        <Form.Control
                          as="textarea"
                          style={{ height: "150px" }}
                          name="description"
                          placeholder="Ingrese toda la informacion necesaria del producto solicitado"
                          {...register("description")}
                        />
                        {errors.description && (
                          <div className="alert alert-danger" role="alert">
                            {errors.description.message}
                          </div>
                        )}
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group controlId="image" className="mb-3 ">
                      <FloatingLabel
                        controlId="product"
                        label="Foto"
                        className="mb-3 notRequired"
                      >
                        <Form.Control
                          type="file"
                          name="image"
                          accept=".jpeg,.jpg,.png"
                          ref={fileRef}
                          onChange={handleChangeImage}
                        />
                      </FloatingLabel>
                    </Form.Group>
                    <div className="d-flex justify-content-end">
                      <p className="requiredBefore small">
                        Campos obligatorios
                      </p>
                    </div>

                    <div className="d-grid gap-2">
                      <Button variant="primary" type="submit" size="lg">
                        Publicar
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PublicacionesNueva;
