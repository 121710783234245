import { createContext, useState } from "react";

function UserProvider({ children }) {
  const [user, setUser] = useState({
    email: null,
    username: null,
    id_role: null,
  });

  const UserContext = createContext();
  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
}

export default UserProvider;
