import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PublicacionesLista from "../components/PublicacionesLista";
import CategoriasService from "../services/Categorias";
import PublicacionesService from "../services/Publicaciones";
import { IMAGE_PATH } from "../constants/Constants.js";
import Loader from "../components/Loader";
import GoBack from "../components/GoBack";
import AuthService from "../services/Auth";
import { set } from "react-hook-form";
import { Button, Modal } from "react-bootstrap";

function CategoriasLista(props) {
  const { id } = useParams();
  const [categoria, setCategoria] = useState([]);
  const [publicaciones, setPublicaciones] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [counter, setCounter] = useState(0);

  // useEffect(() => {
  //   (async () => {
  //     const Categoria = await CategoriasService.get(id);
  //     // console.log("CategoriasService.get(id) =", Categoria);
  //     setCategoria(Categoria);
  //   })();
  // }, []);

  const refresh = () => {
    setCounter(counter + 1);
  };

  useEffect(() => {
    (async () => {
      const Publicaciones = await PublicacionesService.getCategory(id);
      const response = await CategoriasService.get(id);
      console.log("response", response);
      setCategoria(response.name);
      // console.log(
      //   "PublicacionesService.getCategoria(id) =",
      //   Publicaciones.data
      // );
      // setPublicaciones(Publicaciones.data);
      if (Publicaciones.data.length === 0) {
        setEmpty(true);
      } else {
        setPublicaciones(
          Publicaciones.data.filter((publicacion) => publicacion.state == true)
        );
      }
      setLoading(false);

      // setTimeout(() => {
      //   if (publicaciones.length === 0) {
      //     setEmpty(true);
      //   }
      //   // El problema del estado obsoleto desaparece usando una función interna
      //   setCounter((prevCount) => prevCount + 1);
      // }, 2500);

      // console.log(Publicaciones.data.length === 0);
    })();
  }, [counter]);

  return (
    <>
      <div className="row bg-white ">
        <div className="container min-vh-view">
          <div className="row justify-content-center">
            <div className="col-10 arrow">
              <GoBack />
            </div>
            <div className="col-10">
              {loading ? (
                <Loader />
              ) : (
                <>
                  <h3 className="py-2">{categoria}</h3>
                  {empty ? (
                    <div className="card mb-2 mx-1 border-0">
                      <div className="row g-0">
                        <div className="col-2">
                          {/* <img
                          src={`${IMAGE_PATH}/empty.png`}
                          className="img-fluid rounded-start w-75"
                          alt="sin_imagen"
                        /> */}
                        </div>

                        <div className="col-12">
                          <div className="card-body">
                            <div className="d-flex justify-content-between">
                              <div className="text-center">
                                <div>
                                  <i className="bi bi-info-lg fa-4x"></i>
                                </div>

                                <h3 className="card-title h6 fw-normal">
                                  Aún no hay ninguna publicación con esta
                                  categoría
                                </h3>
                                <Link
                                  to="/publicaciones/nueva"
                                  className="text-blue"
                                >
                                  Sé el primero en publicar
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <PublicacionesLista
                        items={publicaciones}
                        auth={props.auth}
                        update={refresh}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoriasLista;
