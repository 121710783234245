import { API_HOST } from "../constants/Constants.js";

const DenunciasService = {
  all() {
    return fetch(`${API_HOST}/complaints`)
      .then((response) => response.json())
      .then((parsed) => parsed);
  },
  async get(id) {
    const response = await fetch(`${API_HOST}/complaints/${id}`);
    const jsonData = await response.json();
    return jsonData;
  },
  async getPublicacion(id) {
    const response = await fetch(`${API_HOST}/complaints/publication/${id}`);
    const jsonData = await response.json();
    return jsonData;
  },
  async create(data) {
    const response = await fetch(`${API_HOST}/complaints`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    });
    const parsed = await response.json();
    return parsed;
  },
};
export default DenunciasService;
