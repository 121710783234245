import PublicacionesListaItem from "./PublicacionesListaItem.js";

function PublicacionesLista(props) {
  // console.log("publicacionesLista ", props);

  return (
    <ul className="list-group align-items-center">
      {props.items.map((item) => (
        <PublicacionesListaItem
          key={item.id_publication}
          item={item}
          update={props.update}
        />
      ))}
    </ul>
  );
}

export default PublicacionesLista;
