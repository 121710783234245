import { API_HOST } from "../constants/Constants";
import {
  storageContains,
  storageDelete,
  storageGet,
  storageSet,
  storaSet,
} from "./Storage.js";
import { db, auth } from "../firebase/Firebase.config";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";

let user = {
  id_user: null,
  email: null,
  id_role: null,
};
let token = null;
let observers = [];
let datos;

const AuthService = {
  async login(credentials) {
    try {
      const response = await fetch(`${API_HOST}/auth/login`, {
        method: "POST",
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });
      const jsonData = await response.json();
      // console.log("jsonData", jsonData);
      // return jsonData;
      if (jsonData.success) {
        user = { ...jsonData.data.user };
        token = jsonData.data.token;
        storageSet({
          user,
          token,
        });
        this.notifyAll();
        return {
          success: true,
        };
      }
      return {
        success: false,
        msg: jsonData.msg,
      };
    } catch (error) {
      return {
        success: false,
        msg: error.message,
        conection: true,
      };
      // console.log("error", error.message);
    }
  },

  async registerWithMysql(credentials) {
    // console.log("registerDataMysql", credentials);
    const response = await fetch(`${API_HOST}/auth/register`, {
      method: "POST",
      body: JSON.stringify(credentials),
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    });
    const jsonData = await response.json();
    console.log("backend jsonData ", jsonData);
    // return jsonData;

    if (jsonData.success) {
      // user = { ...jsonData.data.user };
      // storageSet({
      //   user,
      // });

      return (datos = {
        success: true,
      });
    }
    // //console.log("verificar datos ingresados");
    return (datos = {
      success: false,
      msg: jsonData.msg,
    });
  },

  async restore(credentials) {
    // console.log("registerDataMysql", credentials);
    const response = await fetch(`${API_HOST}/auth/restore`, {
      method: "POST",
      body: JSON.stringify(credentials),
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    });

    const jsonData = await response.json();
    console.log("backend jsonData ", jsonData);
    if (jsonData.success) {
      return (datos = {
        success: true,
      });
    }
    return (datos = {
      success: false,
      msg: jsonData.msg,
    });
  },

  async logout() {
    const response = await fetch(`${API_HOST}/auth/logout`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonResponse = await response.json();
    //console.log("logout jsonResponse", jsonResponse);
    user = {
      id_user: null,
      email: null,
      id_role: null,
    };
    token = null;
    storageDelete(["user", "token"]);
    this.notifyAll();
    // window.location.reload();
    return jsonResponse.success;
  },

  async logoutWithFirebase() {
    signOut(auth);
    user = {
      id_user: null,
      email: null,
      id_role: null,
    };
    storageDelete(["user"]);
  },

  subscribe(callback) {
    observers.push(callback);

    this.notify(callback);

    return () => {
      observers = observers.filter((obs) => obs !== callback);
    };
  },

  notify(callback) {
    callback({ ...this.getUser() });
  },
  notifyAll() {
    observers.forEach((obs) => this.notify(obs));
  },

  getUser() {
    return { ...user };
  },
};

if (storageContains("user")) {
  user = storageGet("user");
  AuthService.notifyAll();
}

export default AuthService;
