import { Link, useNavigate } from "react-router-dom";
import { IMAGE_PATH } from "../constants/Constants.js";
import moment from "moment";
import "moment/locale/es";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import PublicacionesService from "../services/Publicaciones.js";
import UseAuthContext from "../hooks/UseAuthContext.js";

function PublicacionesListaItem(props) {
  // console.log("PublicacionesListaItem = ", props);
  const { AuthConsumer } = UseAuthContext();
  console.log("AuthContext", AuthConsumer);
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => setShow(false);

  const handleDelete = async () => {
    const success = await PublicacionesService.delete(
      props.item.id_publication
    );
    // console.log("Éxito al eliminar: ", success);
    // window.location.reload(true);
    props.update();
    setShow(false);
  };

  const handleShow = () => setShow(true);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="modal-bottom border-bottom"
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title>Opciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-grid gap-2">
            <Link
              className="btn btn-light btn-lg"
              variant="light"
              size="lg"
              to={`/publicaciones/${props.item.id_publication}/editar`}
            >
              <i className="bi bi-pencil pe-2"></i> Editar
            </Link>
            <Button variant="light" size="lg" onClick={handleDelete}>
              <i className="bi bi-trash3 px-2"></i>{" "}
              <span className="text-danger">Eliminar</span>
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-block modal-border-top">
          <div className="d-grid gap-2">
            <Button variant="light" size="lg" onClick={handleClose}>
              <i className="bi bi-x-lg"></i> Cancelar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <li className="pb-3">
        <div className="row">
          <div className="col-3 m-auto">
            <img
              src={
                props.item.image
                  ? IMAGE_PATH + "/productos/" + props.item.image
                  : IMAGE_PATH + "/empty.png"
              }
              alt={props.item.image}
              className="img-fluid rounded-start"
            />
          </div>
          <div className="col-7">
            <Link
              to={`../publicaciones/${props.item.id_publication}`}
              className="row publication"
            >
              <h3 className="h6">{props.item.product}</h3>
              {/* <p className="small">
                {moment(props.item.created_at).format("LL")}
              </p> */}
              {/* {!props.item.state ? (
                <span className="bg-red text-light rounded p-05">
                  En revisión
                </span>
              ) : (
                ""
              )} */}
              {/* {props.auth.user.id_role !== 4 ? (
                <>
                  <p> ${props.item.price} </p>
                </>
              ) : props.auth.user.id_user == props.item.id_user ? (
                <>
                  <p>${props.item.price}</p>
                </>
              ) : (
                ""
              )} */}
              <p className="h7 catDescription">{props.item.description}</p>
            </Link>
          </div>
          <div className="col-2 d-flex position-relative align-items-center">
            {AuthConsumer.user.id_user === props.item.id_user ? (
              <i
                className="bi bi-three-dots-vertical position-absolute top-0 right-2"
                type="button"
                onClick={handleShow}
              ></i>
            ) : (
              ""
            )}
            <i className="bi bi-arrow-right-short fa-2x"></i>
          </div>
        </div>
      </li>
    </>
  );
}

export default PublicacionesListaItem;
