import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import AuthService from "../services/Auth";
import CategoriasService from "../services/Categorias";
import Notificaciones from "../services/Notificaciones";

function Navbar(props) {
  //  console.log("props = ", props);
  // console.log("carga nav", AuthService.getUser());
  const [user] = useState(AuthService.getUser());
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const getLocation = pathname.split("/");
  console.log("getLocation = ", getLocation);
  const { id } = useParams();
  const [notificaciones, setNotificaciones] = useState(false);
  const [quantity, setQuantity] = useState();
  // useEffect(() => {
  //   (async () => {
  //     const Categoria = await CategoriasService.get(id);
  //     // console.log("CategoriasService.get(id) =", Categoria);
  //     setCategoria(categoria);
  //   })();
  // }, []);

  async function notificacionesAlert() {
    const response = await Notificaciones.getUserInactive(user.id_user);
    console.log("Notificaciones.getUserInactive", response);
    if (response.success) {
      setNotificaciones(true);
      setQuantity(response.quantity);
    }
  }

  useEffect(() => {
    notificacionesAlert();
    // console.log("notificacionesAll =", contraofertas);
  }, [notificacionesAlert]);

  return (
    <>
      <nav className="container navbar fixed-top navbar-expand-lg navbar-dark bg-blue justify-content-between">
        {/* <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      > */}
        {/* <span className="navbar-toggler-icon"></span> */}
        <img
          src="/imgs/logoSmall.png"
          alt="logo app"
          className="img-fluid w-8 mx-2"
          type="button"
          // data-bs-toggle="collapse"
          // data-bs-target="#navbarSupportedContent"
          // aria-controls="navbarSupportedContent"
          // aria-expanded="false"
          // aria-label="Toggle navigation"
          onClick={() => navigate("/home")}
        />
        {/* </button> */}
        <Link className="navbar-brand me-0" to="/">
          <h2 className="h2 m-0  text-white">
            {/* {getLocation[1] === ""
              ? "Home"
              : getLocation[1].charAt(0).toUpperCase() +
                getLocation[1].slice(1)} */}
            {getLocation[1] === "home"
              ? "Home"
              : getLocation[1] === "categorias"
              ? "Categorías"
              : getLocation[1] === "publicaciones"
              ? "Publicaciones"
              : getLocation[1] === "contraofertas"
              ? "Contraofertas"
              : getLocation[1] === "perfil"
              ? "Perfil"
              : getLocation[1] === "busqueda"
              ? "Búsqueda"
              : getLocation[1] === "notificaciones"
              ? "Notificaciones"
              : ""}
          </h2>
        </Link>
        <div>
          {user.id_role === 4 ? (
            ""
          ) : (
            <Link className="btn px-2" to="/busqueda">
              <i className="bi bi-search text-white"></i>
            </Link>
          )}
          <Link className="btn px-2 position-relative" to="/notificaciones">
            <i className="bi bi-bell text-white"></i>
            {notificaciones ? (
              <p className="bg-red rounded-circle s-small">{quantity}</p>
            ) : (
              ""
            )}
          </Link>
        </div>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <Link className="nav-link" to="/home">
              Home
            </Link>
            <Link className="nav-link" to="/categorias">
              Categorias
            </Link>
            <Link className="nav-link" to="/publicaciones">
              Publicaciones
            </Link>
            <Link className="nav-link" to="/contraofertas">
              Contraofertas
            </Link>
            <Link className="nav-link" to="/perfil">
              Perfil
            </Link>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
