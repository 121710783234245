import { Button, FloatingLabel, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, Navigate, useNavigate } from "react-router-dom";
import AuthService from "../services/Auth.js";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import Loader from "../components/Loader.js";
import UseAuthContext from "../hooks/UseAuthContext.js";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Formato de mail invalido")
    .required("Campo obligatorio"),
  password: yup
    .string()
    .required("Campo obligatorio")
    .min(5, "Campo minimo de 5 caracteres"),
});

function Login(props) {
  const { AuthConsumer } = UseAuthContext();
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [errorLogin, setErrorLogin] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (AuthConsumer.logged === true) {
      navigate("/home");
    }
    setLoading(false);
  }, []);

  const onSubmit = async (data) => {
    // //console.log("Data: ", data);
    setLoading(true);

    loginWithMysql(data);
  };
  // console.log("errorLogin = ", errorLogin);
  const handleCloseAlert = () => {
    setError(false);
  };

  const loginWithMysql = async (data) => {
    const response = await AuthService.login({
      email: data.email,
      password: data.password,
    });
    console.log("login response", response);
    if (response.success) {
      // props.onLogin(AuthService.getUser());
      navigate("/home");
    } else if (response.conection || response.msg == undefined) {
      setErrorLogin("!UY! Algo salió mal. Intentá de nuevo en unos minutos.");
      setError(true);
    } else {
      setErrorLogin(response.msg);
      setError(true);
    }

    setLoading(false);
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      {/* {props.logged.logged ? (
        <Navigate to="/home" />
      ) : ( */}
      <>
        <div className="container ">
          <div className="row">
            <div className="col-12">
              <div className="text-center my-5">
                <img
                  src="/imgs/logo.png"
                  className="img-fluid w-75"
                  alt="logo app"
                />
                <h1 className="fontCo">
                  <span className="text-red">CONTRA</span>
                  <span className="text-blue">OFERTA</span>
                </h1>
              </div>
              <h2>Bienvenido</h2>
              <p>Por favor, ingresa a tu cuenta</p>
              {/* <h2 className="text-center h4">Iniciar sesión</h2> */}
              {error && (
                <div className="alert alert-danger row" role="alert">
                  <div className="col-12">
                    <i className="bi bi-x-octagon fa-2x pe-2"></i> {errorLogin}
                  </div>
                  {/* <div className="col-2">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                      // onClick={handleCloseAlert}
                    ></button>
                  </div> */}
                </div>
              )}
              <Form
                action="#"
                method="post"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email"
                  className="mb-3"
                >
                  <Form.Control
                    type="email"
                    placeholder="Escribe tu mail"
                    name="email"
                    {...register("email")}
                  />
                  {errors.email && (
                    <div className="alert alert-danger" role="alert">
                      {errors.email.message}
                    </div>
                  )}
                </FloatingLabel>

                <FloatingLabel controlId="floatingPassword" label="Contraseña">
                  <Form.Control
                    type="password"
                    placeholder="Ingresa tu contraseña"
                    name="password"
                    {...register("password")}
                  />
                  {errors.password && (
                    <div className="alert alert-danger" role="alert">
                      {errors.password.message}
                    </div>
                  )}
                </FloatingLabel>

                <div className="d-grid gap-2 py-3">
                  <Button variant="danger" size="lg" type="submit">
                    Iniciar sesión
                  </Button>
                </div>
              </Form>
              <div className="text-right">
                <Link to={"restablecerClave"} className="text-blue">
                  ¿Olvidastes tu contraseña?
                </Link>
              </div>

              {/* <div className="row py-4">
                  <div className="col-12 p-0">
                    <div className="text-center">
                      <p>o inicia sesion con</p>
                      <div className="mb-1">
                        <div className="square">
                          <i className="bi bi-google fa-2x px-3 fa-google"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              <div className="text-center my-5">
                <p>
                  ¿No tienes una cuenta?
                  <Link to="/registro" className="text-blue">
                    {" "}
                    Crear cuenta
                  </Link>
                </p>
              </div>
              {/* <div className="text-center p-4">
                  <p>
                    Al registrarte, estás de acuerdo con nuestros
                    <Link to="/">Términos y condiciones</Link>
                  </p>
                </div> */}
              <div className="py-4 small">
                Al registrarte, estás de acuerdo con nuestros{" "}
                <Link to={"terminos"} className="text-blue">
                  Términos y condiciones
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
      {/* )} */}
    </>
  );
}

export default Login;
